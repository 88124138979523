import React, { useState, useEffect } from "react";
import ReactHlsPlayer from "react-hls-player";
import { service } from "../../Network/service";
import "./TrailerPopUp.css";
import CloseIcon from "@material-ui/icons/Close";

const border = () => {
  let devStyle = {
    borderRadius: "15px",
    height: "auto",
  };

  let mQMob = window.matchMedia("(max-width: 480px)");
  let mQLowTabIpad = window.matchMedia(
    "(min-width: 481px) and (max-width: 767px)"
  );
  let mQPotraitTabIpad = window.matchMedia(
    "(min-width: 768px) and (max-width: 1024px)"
  );
  let mQLapDesk = window.matchMedia(
    "(min-width: 1025px) and (max-width: 1280px)"
  );
  let mQLargeDev = window.matchMedia("(min-width: 1281px)");

  if (mQMob.matches) {
    devStyle = { ...devStyle, width: "275px" };
  } else if (mQLowTabIpad.matches) {
    devStyle = { ...devStyle, width: "300px" };
  } else if (mQPotraitTabIpad.matches) {
    devStyle = { ...devStyle, width: "400px" };
  } else if (mQLapDesk.matches) {
    devStyle = { ...devStyle, width: "500px" };
  } else if (mQLargeDev.matches) {
    devStyle = { ...devStyle, width: "600px" };
  } else {
    devStyle = { ...devStyle, width: "100%" };
  }

  return devStyle;
};

var isSafari = false;

const TrailerPopUp = (props) => {
  const [videoPlayer, setVideoPlayer] = useState();
  useEffect(() => {
    isSafari =
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document);

    service.playerToken().then((tokenResponse) => {
      let newURL = "";
      if (props.apiData.teaser && props.apiData.teaser_flag == 1) {
        let arr = props.apiData.teaser.split("/").reverse();
        newURL =
          "https://poppo.tv/playlist/playlist.m3u8?id=" +
          arr[1] +
          "&token=" +
          tokenResponse.data.data +
          "&type=trailer&auth=<authtoken>";
      } else {
      }

      setTimeout(() => {
        setVideoPlayer(
          !isSafari ? (
            <ReactHlsPlayer
              id="singleVideo"
              url={newURL}
              controls={true}
              autoplay
              style={border()}
            />
          ) : (
            <video
              controls={true}
              id="singleVideo"
              className="video-js vjs-default-skin"
              autoPlay={true}
              style={border()}
            >
              <source src={newURL} type="application/x-mpegURL" />
            </video>
          )
        );
      }, 1000);
    });
  }, []);

  if (!props.show) {
    return null;
  } else {
    return (
      <div className="modal-trailer" onClick={props.onClose}>
        <div
          className="modal-trailer-content"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="modal-trailer-title-container">
            <div className="modal-trailer-title">Trailer</div>

            <CloseIcon className="modal-close-btn" onClick={props.onClose} />
          </div>
          <div className="modal-trailer-detail-container">
            <div className="modal-trailer-showName">
              {props.apiData.show_name}
            </div>
            <div className="modal-trailer-metadata">
              {props.apiData.director}{" "}
              {props.apiData.director && props.apiData.year && "."}{" "}
              {props.apiData.year}
            </div>
          </div>
          <div className="modal-trailer-body">{videoPlayer}</div>
        </div>
      </div>
    );
  }
};

export default TrailerPopUp;
