import React, { useState, useEffect } from "react";
import { service } from "../../Network/service";
import "./Search.css";
import ThumbnailGrid from "../ThumbnailGrid/ThumbnailGrid";
import ClipLoader from "react-spinners/ClipLoader";
import { BallBeat } from "react-pure-loaders";

const Search = (props) => {
  const [key, setKey] = useState();
  const [results, setResults] = useState();
  const [timeOut, setTimeOut] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.getElementById("scroll-top").scroll(0, 0);
    window.scrollTo(0, 0);

    if (props.location.search) {
      if (results) {
        setResults();
        setKey();
      }
      const urlParams = new URLSearchParams(props.location.search);
      let keyword = urlParams.get("keyword");
      setKey(keyword);
      if (timeOut) {
        clearTimeout(timeOut);
      }
      setLoading(true);
      setTimeOut(
        setTimeout(function () {
          service.getShows(keyword).then((response) => {
            if (response.success === true && response.data.length > 0) {
              setResults(response.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          });
        }, 750)
      );
    }
  }, [props.location.search]);

  const updateUseEffect = () => {
    service.getShows(key).then((response) => {
      if (response.success === true && response.data.length > 0) {
        setResults(response.data);
      }
    });
  };

  if (!results && loading == false) {
    // no results
    return (
      <div>
        {key && (
          <div className="ml-4 mb-2">
            <span className="title">Results for: </span>
            <h3 className="search-title">{key}</h3>
          </div>
        )}
        <div
          className="row d-flex justify-content-center"
          style={{ marginTop: "50px" }}
        >
          <div
            className="alert alert-warning col-md-7"
            role="alert"
            style={{ width: "80vw" }}
          >
            <h4 className="alert-heading">Oops!</h4>
            <p>No Result found</p>
          </div>
        </div>
      </div>
    );
  } else if (!results && loading == true) {
    //loader..
    return (
      <div>
        {key && (
          <div className="ml-4 mb-2">
            <span className="title">Results for: </span>
            <h3 className="search-title">{key}</h3>
          </div>
        )}
        <div className="loader__wrapper">
          <div className="loader__container">
            <h2>LOADING &nbsp;</h2>
            <BallBeat color={"#feca3e"} loading={true} />
          </div>
        </div>
      </div>
    );
  } else {
    //results..
    return (
      <div>
        {key && (
          <div className="ml-4 mb-2">
            <span className="title">Results for: </span>
            <h3 className="search-title">{key}</h3>
          </div>
        )}
        <ThumbnailGrid shows={results} clickHandler={updateUseEffect} />
      </div>
    );
  }
};

export default Search;
