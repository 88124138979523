import React, { useState, useEffect } from "react";
import Subscription from "./Subscription";
import { service } from "../../Network/service";
import { ToastsContainer, ToastsStore } from "react-toasts";

const SubscriptionContainer = (param, androidData, isAndroid) => {
  const [premiumFlag, setPremiumFlag] = useState();
  useEffect(() => {
    // let uId = service.getCookie("guestUserId");
    // let user_id = service.getCookie("userId");
    // if (user_id) {
    //   uId = user_id;
    // }
    if (
      service.getCookie("userId") != service.getCookie("guestUserId") &&
      (service.getCookie("userId") != null ||
        service.getCookie("userId") != undefined)
    ) {
      let user_id = service.getCookie("userId");
      service.userSubscription(user_id).then((response) => {
        if (response.success == true) {
          if (response.forcibleLogout === true) {
            alert(
              "Sorry, You’ve reached the maximum Device limit. Please log in again!"
            );
            service.logoutAll(user_id).then((res) => {
              setTimeout(() => {
                redirectToLogin();
              }, 1000);
            });
          } else if (response.session_expired === true) {
            ToastsStore.warning("Sorry! Session Has Expired");
            setTimeout(() => {
              redirectToLogin();
            }, 1000);
          } else {
            // let subCount =
            //   response.data &&
            //   response.data.filter(
            //     (e) => e.subscription_type_id == 4 || e.subscription_type_id == 3
            //   );

            if (response.data && response.data.length > 0) {
              //have monthly or yearly subscription
              setPremiumFlag(true);
            } else {
              //No monthly or yearly subscription
              setPremiumFlag(false);
            }
          }
        }
      });
    }
  }, [param]);

  const redirectToLogin = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    localStorage.removeItem("deviceAnalytics");
    service.setCookie("isLoggedIn", false, 30);
    localStorage.removeItem("isLoggedIn");
    service.eraseCookie("showId");
    service.eraseCookie("isScreeningUser");
    service.eraseCookie("userName");
    service.eraseCookie("userId");
    service.eraseCookie("userEmail");
    service.eraseCookie("subscriptionId");
    setTimeout(() => {
      let isAndroid = localStorage.getItem("isAndroid");
      if (isAndroid == true || isAndroid == "true") {
        window.location.href = "https://staging.justwatchme.tv/webviewfailed";
      } else {
        window.location.href = "/signin";
      }
    }, 1500);
  };

  return (
    <section className="categoryWrapper">
      <div className="_2vKa8"></div>
      <div className="container categoryHeadWrapper">
        <div className="categoryLinkWrapper">
          <div>
            {/* <p className="text-head">Subscription Packages Options</p> */}

            <div className="subscribe-box">
              <p style={{ fontSize: "15px", color: "#fff" }}>
                <span style={{ fontSize: "15px", color: "#fff" }}>
                  <span style={{ fontSize: "15px", color: "#fff" }}>
                    <span>
                      <p
                        style={{
                          fontSize: "21px",
                          color: "#fdc93b",
                          //   color: "#57e6fd",
                          textAlign: "center",
                        }}
                      >
                        {premiumFlag == true ? (
                          <b>This premium title is only available</b>
                        ) : premiumFlag == false ? (
                          <b>
                            SUBSCRIBE for access to this title and more,
                            ad-free! Subscribers also gain access to more
                            exclusive and premium content!
                          </b>
                        ) : null}
                      </p>
                    </span>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        margin: "0",
                        color: "white",
                      }}
                    >
                      Subscription Package Options:
                    </p>
                    <br />
                  </span>
                </span>
                <span>
                  - Payments can be made by credit card using Stripe, or by
                  Paypal.
                  <br />
                </span>
                <span>
                  - Subscription will auto-renew monthly unless cancelled 24
                  hours prior to the end of the billing cycle. Unused portions
                  of cancelled subscriptions will not be refunded.
                  <br />
                </span>
                <span>
                  - Subscription options and cancellation can be managed at any
                  time by logging into justwatchme.tv and selecting “account
                  settings”.
                  <br />
                </span>
              </p>
            </div>
          </div>
        </div>
        <Subscription
          param={param.param}
          androidData={androidData}
          isAndroid={isAndroid}
        />
        <ToastsContainer store={ToastsStore} />
      </div>
    </section>
  );
};
export default SubscriptionContainer;
