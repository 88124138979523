import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { service } from "../../Network/service";
import ThumbnailGrid from "../ThumbnailGrid/ThumbnailGrid";
import "../CategoryList/CategoryList.css";
const queryString = require("query-string");

const NewArrivals = () => {
  var { search } = useLocation();
  const parsed = queryString.parse(search);
  const [emptyFlag, setEmptyFlag] = useState(false);
  const [filmOfTheDay, setFilmOfTheDayt] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    updateUseEffect();
  }, []);

  const updateUseEffect = () => {
    service.getFileOfTheWeek().then((response) => {
      if (
        response.success == true &&
        response.data &&
        response.data.length > 0
      ) {
        setFilmOfTheDayt(response.data);
      } else {
        setEmptyFlag(true);
      }
    });
  };

  return (
    <div>
      <div className="category__name">
        <h1 className="category__name__text">Film of the week</h1>
      </div>
      <ThumbnailGrid shows={filmOfTheDay} clickHandler={updateUseEffect} />
    </div>
  );
};

export default NewArrivals;
