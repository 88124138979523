import React from "react";
import "./KeyArticles.css";
import LaunchIcon from "@material-ui/icons/Launch";

let url = "https://gizmeon.s.llnwi.net/vod/thumbnails/show_logo/";
let image =
  "	https://gizmeon.s.llnwi.net/vod/thumbnails/thumbnails/1631034373534.jpg";

const KeyArticles = ({ keyArtwork }) => {
  return (
    <div className="articleSection">
      <div className="showingCategoryWrapper">
        <div className="articleSectionContainer">
          <h2 className="articleSectionHeading">About</h2>
          <div className="keyArtworkRowCol">
            {keyArtwork.map((item, index) => {
              return (
                <div className="keyArtworkBoxSectionWrapper">
                  <div className="keyArtworkBoxSection">
                    <div className="keyArtworkImg">
                      <img src={url + item.image} alt="" loading="lazy" />
                      {item.imdb_link ? (
                        <div className="keyartworkIcon" title="IMDb">
                          <a href={item.imdb_link} target="_blank">
                            <LaunchIcon
                              style={{ color: "white", cursor: "pointer" }}
                            />
                          </a>
                        </div>
                      ) : null}
                    </div>
                    <div className="keyArtworkDetailsWrapper">
                      <div className="keyArticleSectionTitle">{item.title}</div>
                      <div
                        className="keyArtworkSectionPara"
                        title={item.description}
                      >
                        {item.description}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyArticles;
