import React, { useState, useEffect } from "react";
import Slider from "../Slider/Slider";
import SliderWithBanner from "../SliderWithBanner/SliderWithBanner";

const Category = ({ categoryDetails, clickHandler, onClickTralier }) => {
  const [category, setCategory] = useState();
  useEffect(() => {
    setCategory(categoryDetails);
  }, [categoryDetails]);

  return (
    <div>
      {category && !category.banner && (
        <Slider
          category={category}
          clickHandler={clickHandler}
          onClickTralier={onClickTralier}
          isBanner={false}
        />
      )}
      {category && category.banner && (
        <SliderWithBanner
          category={category}
          clickHandler={clickHandler}
          onClickTralier={onClickTralier}
        />
      )}
    </div>
  );
};

export default Category;
