import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { service } from "../../Network/service";
import ThumbnailGrid from "../ThumbnailGrid/ThumbnailGrid";
import "./CategoryList.css";

const queryString = require("query-string");
let url = "https://gizmeon.s.llnwi.net/vod/thumbnails/thumbnails/";

const CategoryList = () => {
  var { search } = useLocation();
  const parsed = queryString.parse(search);
  let isLoggedIn = localStorage.getItem("isLoggedIn");
  const [emptyFlag, setEmptyFlag] = useState(false);
  const [showList, setShowList] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [Cbanner, setCbanner] = useState();
  const [Csynopsis, setCsynopsis] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    updateUseEffect();
  }, [search]);

  const updateUseEffect = () => {
    if (parsed.category_id === "playlist") {
      let isLoggedIn = localStorage.getItem("isLoggedIn");
      if (isLoggedIn === "true") {
        service.playList().then((response) => {
          setCategoryName(parsed.category_name);
          if (response.data && response.data.length > 0) {
            setShowList(response.data);
          } else {
            setEmptyFlag(true);
          }
        });
      }
    } else if (parsed.category_name === "Watch for free") {
      service.freeVideos(parsed.category_id).then((response) => {
        setCategoryName(parsed.category_name);
        if (response.data && response.data.length > 0) {
          setShowList(response.data);
        } else {
          setEmptyFlag(true);
        }
      });
    } else {
      service.showsByCategoryMore(parsed.category_id).then((response) => {
        if (
          response.data &&
          response.data.shows &&
          response.data.shows.length > 0
        ) {
          setShowList(response.data.shows);
          setCbanner(response.data.banner);
          setCsynopsis(response.data.synopsis);
          setCategoryName(response.data.category_name);
        } else {
          setEmptyFlag(true);
        }
      });
    }
  };

  if (
    parsed.category_id === "playlist" &&
    localStorage.getItem("isLoggedIn") != "true"
  ) {
    return (
      <div
        className="row d-flex justify-content-center"
        style={{ marginTop: "130px" }}
      >
        <div
          className="alert alert-warning col-md-7"
          role="alert"
          style={{ width: "80vw" }}
        >
          <h4 className="alert-heading">Oops! Not Available</h4>
          <p>
            To enable this feature, Please set up your account
            <a
              href="/register"
              style={{ margin: "0 10px 0 10px", borderBottom: "1px solid" }}
            >
              Sign up
            </a>
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        {Cbanner ? (
          <div className="category__banner__wrapper">
            <div className="category__banner__image">
              <img src={url + Cbanner} />{" "}
              <div className="banner__content__wrapper">
                <div className="category__name">
                  <h1 className="banner__category__name">{categoryName}</h1>
                </div>
                {Csynopsis && (
                  <div className="synopsis__container">{Csynopsis}</div>
                )}
              </div>
              <div className="banner__arrow">
                <div className="bannerArrowAnimation">
                  <div
                    className="bsCionDownWrapper"
                    style={{
                      transform: "rotate(270deg)",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <svg
                      viewBox="0 0 10 25"
                      fill="#FFFFFF"
                      className="bgImageSectionBtnSVGshow"
                    >
                      <path d="M.22 13c-.3-.45-.29-1.04.01-1.5L7.7.27a.6.6 0 0 1 .86-.16l1.18.82c.28.2.36.58.17.86L2.93 12.28 9.9 23.21c.18.29.1.67-.18.86l-1.2.8a.6.6 0 0 1-.85-.18L.22 13z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="category__name">
            <h1 className="category__name__text">{categoryName}</h1>
          </div>
        )}

        {emptyFlag == false ? (
          <ThumbnailGrid shows={showList} clickHandler={updateUseEffect} />
        ) : (
          <div
            className="row d-flex justify-content-center"
            style={{ marginTop: "50px" }}
          >
            <div
              className="alert alert-warning col-md-7"
              role="alert"
              style={{ width: "80vw" }}
            >
              <h4 className="alert-heading">Oops!</h4>
              <p>Video list is empty</p>
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default CategoryList;
