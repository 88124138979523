import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { service } from "./service";
import ThumbnailGrid from "../ThumbnailGrid/ThumbnailGrid";
import "./ScreeningRoom.css";

function ScreeningRoom() {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);

  const [category, setCategory] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const screeningUser = service.getCookie("isScreeningUser");
    if (screeningUser) {
      updateFunction();
    } else {
      window.location.href = "/home";
    }
  }, [login]);

  const updateFunction = () => {
    service.getScreenUserViedio().then((response) => {
      if (response.success === true && response.data.length > 0) {
        var data = response.data;
        setCategory(data);
      }
    });
  };

  return (
    <div style={{ padding: "40px 0" }}>
      <div className="screeningRoomTitle">Screening room</div>
      {category && category.length > 0 ? (
        <ThumbnailGrid shows={category} clickHandler={updateFunction} />
      ) : (
        <div
          className="row d-flex justify-content-center"
          style={{ marginTop: "50px" }}
        >
          <div
            className="alert alert-warning col-md-7"
            role="alert"
            style={{ width: "80vw" }}
          >
            <h4 className="alert-heading">Oops!</h4>
            <p>No Screening room videos available</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ScreeningRoom;
