import React, { useState, useEffect } from "react";
import FooterInfo from "../Basic/FooterInfo";
import { Link, useHistory, Redirect, useLocation } from "react-router-dom";
import logo from "../../img/logo.png";
import youtube from "../../images/youtube.png";
import twitter from "../../images/twitter.png";
import linkedin from "../../images/linkedin.png";
import { useSelector } from "react-redux";
import partnerlogo from "../../images/partner_logo.png";
const Footer = () => {
  const isAndroid = useSelector((state) => state.isAndroid);
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const functionOnclick = (path) => {
    history.push({ pathname: path });
  };


  return (
    <div className="footer">
      <div className="container">
        <div
          className="row footerLogoSocial "
          style={{ marginRight: "-2px", marginLeft: "-2px" }}
        >
          <Link to="/home">
            <div className="footerLogo">
              <img src={logo} style={{ cursor: "pointer" }} width={60} />
            </div>
          </Link>
          <div className="_23TJ8"></div>
          <div className="col col-6 col-lg-3 col-xl-1-5 col-xxl-2 socialTray">
            <div className="socialLinkContainer">
              <div className="footerSLWrapper">
              <a 
                  className="partnerlogo"
                >
                  <img
                    src={partnerlogo}
                    style={{ width: "100%", opacity: "0.8", margin: "0px" }}
                  />
                </a>
                <a
                  href="https://www.facebook.com/JWM.tv"
                  rel="noopener"
                  target="_blank"
                  className="linkButton footerFacebook"
                >
                  <svg
                    className="svgIcon facebookIcon"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 20 20"
                    style={{ fill: "currentcolor" }}
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M2 0C.938 0 0 1.063 0 1.97v16.093C0 19.03 1.063 20 2 20h9v-8H8V9h3V7c-.318-2.573 1.26-3.98 4-4 .668.02 1.617.103 2 0v3h-2c-.957-.16-1.2.436-1 1v2h3l-1 3h-2v8h3.938c1.03 0 2.062-.938 2.062-1.938V1.97C20 1.03 18.937 0 17.937 0H2z"
                    ></path>
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/justwatchme_tv/"
                  rel="noopener"
                  target="_blank"
                  className="linkButton footerInsta"
                >
                  <svg
                    className="svgIcon"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 20 20"
                    style={{ fill: "currentcolor" }}
                  >
                    <g fill="currentColor" fillRule="evenodd">
                      <path d="M10 0C7.284 0 6.944.012 5.877.06 4.813.11 4.087.278 3.45.525c-.658.256-1.216.598-1.772 1.153C1.123 2.234.78 2.792.525 3.45.278 4.086.11 4.812.06 5.877.012 6.944 0 7.284 0 10s.012 3.057.06 4.123c.05 1.065.218 1.79.465 2.428.256.658.598 1.216 1.153 1.77.556.558 1.114.9 1.772 1.155.636.248 1.363.417 2.427.464 1.067.048 1.407.06 4.123.06s3.057-.012 4.123-.06c1.064-.048 1.79-.217 2.428-.465.658-.255 1.216-.597 1.77-1.154.558-.554.9-1.112 1.155-1.77.248-.636.417-1.362.464-2.427.048-1.066.06-1.407.06-4.123s-.012-3.056-.06-4.123c-.048-1.065-.217-1.79-.465-2.427-.255-.658-.597-1.216-1.154-1.772-.554-.555-1.112-.897-1.77-1.153C15.915.278 15.188.11 14.124.06 13.057.012 12.716 0 10 0m0 2c2.606 0 2.914.01 3.943.057.952.044 1.468.202 1.812.336.455.177.78.39 1.123.73.34.34.552.667.73 1.12.133.346.292.862.335 1.814C17.99 7.087 18 7.394 18 10s-.01 2.914-.057 3.943c-.043.952-.202 1.468-.335 1.812-.178.455-.39.78-.73 1.123-.343.34-.668.552-1.123.73-.344.133-.86.292-1.812.335-1.03.047-1.337.057-3.943.057s-2.914-.01-3.943-.057c-.952-.043-1.468-.202-1.813-.335-.454-.178-.78-.39-1.12-.73-.342-.343-.554-.668-.73-1.123-.135-.344-.293-.86-.337-1.812C2.01 12.913 2 12.606 2 10s.01-2.914.057-3.943c.044-.952.202-1.468.336-1.813.177-.454.39-.78.73-1.12.34-.342.667-.554 1.12-.73.346-.135.862-.293 1.814-.337C7.087 2.01 7.394 2 10 2"></path>
                      <path d="M10 13c-1.657 0-3-1.343-3-3 0-1.656 1.343-3 3-3s3 1.344 3 3c0 1.657-1.343 3-3 3m0-8c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5m6 0c0 .553-.447 1-1 1-.553 0-1-.447-1-1 0-.553.447-1 1-1 .553 0 1 .447 1 1"></path>
                    </g>
                  </svg>
                </a>
               
                {/* <a
                  href="https://www.youtube.com/channel/UCAkWry4Qibli7L1RRilC-1w"
                  rel="noopener"
                  target="_blank"
                  className="linkButton footerTwitter"
                  style={{ margin: "0px" }}
                >
                  <img
                    src={youtube}
                    style={{
                      width: "27px",
                      marginTop: "3px",
                      marginRight: "0px",
                    }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/capital-motion-picture-group/"
                  rel="noopener"
                  target="_blank"
                  className="linkButton footerTwitter"
                  style={{ margin: "0px" }}
                >
                  <img
                    src={linkedin}
                    style={{ width: "32px", opacity: "0.8", margin: "0px" }}
                  />
                </a> */}
                <a
                  href="https://twitter.com/JustwatchmeTV"
                  rel="noopener"
                  target="_blank"
                  className="linkButton footerTwitter"
                  style={{ margin: "0px" }}
                >
                  <img
                    src={twitter}
                    style={{ width: "32px", opacity: "0.8", margin: "0px" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row footerSeperator">
          <div className="col col-4 col-lg-3 col-xl-1-5 col-xxl-2 footerLine"></div>
          <div className="col col-4 col-lg-3 col-xl-1-5 col-xxl-2 footerLine"></div>
        </div>
        <div
          className="row footerMenu"
          style={{
            marginRight: "-2px",
            marginLeft: "-2px",
            lineHeight: "1.4rem",
          }}
        >
          <div className="col col-4 col-lg-3 col-xl-1-5 col-xxl-2">
            <ul className="footerMenuContainer">
              <li className="footerMenuHead">COMPANY</li>
              <li>
                {" "}
                <div
                  style={{ color: "#fff" }}
                  onClick={() => {
                    functionOnclick("/aboutus");
                  }}
                  rel="noopener"
                  target="_self"
                  className="linkButton"
                >
                  About Us
                </div>
              </li>
            </ul>
          </div>
          <div className="col col-4 col-lg-3 col-xl-1-5 col-xxl-2">
            <ul className="footerMenuContainer">
              <li className="footerMenuHead">SUPPORT</li>
              <li>
                <div
                  style={{ color: "#fff" }}
                  className="linkButton"
                  onClick={() => {
                    functionOnclick("/contactsupport");
                  }}
                >
                  Contact Support
                </div>
              </li>
            </ul>
          </div>
          <div className="col col-4 col-lg-3 col-xl-1-5 col-xxl-2">
            <ul className="footerMenuContainer">
              <li className="footerMenuHead">PARTNERS</li>
              <li>
                <div
                  style={{ color: "#fff" }}
                  rel="noopener"
                  target="_self"
                  onClick={() => {
                    functionOnclick("/contactsupport");
                  }}
                  className="linkButton"
                >
                  Advertise with Us
                </div>
              </li>
              <li>
                <div
                  style={{ color: "#fff" }}
                  className="linkButton"
                  onClick={() => {
                    functionOnclick("/contactsupport");
                  }}
                >
                  Partner with Us
                </div>
              </li>
            </ul>
          </div>
          <div className="col col-3 col-lg-2 col-xl-1-5 col-xxl-2">
            <ul className="footerMenuContainer">
              <li className="footerMenuHead">GET THE APPS</li>
              <li>
                <div
                  style={{ color: "#fff" }}
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.justwatchme.android"
                    );
                  }}
                  rel="noopener"
                  target="_self"
                  className="linkButton"
                >
                  Android
                </div>
              </li>
              <li>
                <div
                  style={{ color: "#fff" }}
                  onClick={() => {
                    window.open(
                      "https://channelstore.roku.com/details/bbb7e9cc133ef10afd6f14748ac0695d"
                    );
                  }}
                  rel="noopener"
                  target="_self"
                  className="linkButton"
                >
                  Roku
                </div>
              </li>
              <li>
                <div
                  style={{ color: "#fff" }}
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.justwatchme.androidtv"
                    );
                  }}
                  rel="noopener"
                  target="_self"
                  className="linkButton"
                >
                  Android TV
                </div>
              </li>
              <li>
                <div
                  style={{ color: "#fff" }}
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/in/app/justwatchme-tv/id1577160113"
                    );
                  }}
                  rel="noopener"
                  target="_self"
                  className="linkButton"
                >
                  iOS
                </div>
              </li>
              <li>
                <div
                  style={{ color: "#fff" }}
                  onClick={() => {
                    window.open(
                      "https://www.amazon.com/Capital-Motion-Picture-Group-Inc/dp/B09G6TJTG7"
                    );
                  }}
                  rel="noopener"
                  target="_self"
                  className="linkButton"
                >
                  Fire TV
                </div>
              </li>
              <li>
                <div
                  style={{ color: "#fff" }}
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/us/app/justwatchme-tv/id1577160113#?platform=appleTV"
                    );
                  }}
                  rel="noopener"
                  target="_self"
                  className="linkButton"
                >
                  Apple TV
                </div>
              </li>
            </ul>
          </div>

          <div className="col col-4 col-lg-2 col-xl-1-5 col-xxl-2">
            <ul className="footerMenuContainer">
              <li className="footerMenuHead">LEGAL</li>
              <li>
                <div
                  style={{ color: "#fff" }}
                  onClick={() => {
                    functionOnclick("/privacyPolicy");
                  }}
                  className="linkButton"
                >
                  Privacy Policy (Updated)
                </div>
              </li>
              <li>
                <div
                  style={{ color: "#fff" }}
                  onClick={() => {
                    functionOnclick("/termOfUse");
                  }}
                  className="linkButton"
                >
                  Terms of Use (Updated)
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <FooterInfo />
    </div>
  );
};
export default Footer;
