import React, { useEffect } from "react";
import "./Slider.css";
import SliderItem from "./SliderItem";
import SlickSlider from "react-slick";
import { useHistory } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SamplePrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className + " " + "slick__carouselPrev"} onClick={onClick}>
      <div className="sliderPrewArrow">
        <img
          src="https://img.icons8.com/ios-glyphs/30/000000/expand-arrow--v1.png"
          style={{ transform: "rotate(90deg)" }}
        />
      </div>
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={className + " " + "slick__carouselNext"}
      onClick={onClick}
      style={{ zIndex: "999999", width: "50px" }}
    >
      <div className="sliderNextArrow">
        <img
          src="https://img.icons8.com/ios-glyphs/30/000000/expand-arrow--v1.png"
          style={{ transform: "rotate(90deg)" }}
        />
      </div>
    </div>
  );
};

const Slider = ({ category, clickHandler, isBanner, onClickTralier }) => {
  const history = useHistory();
  let path = window.location.pathname;
  path = path.length == 1 ? "/home" : path; 
  const settings = {
    dots: false,
    centerMode: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 2100,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1147,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="standardSlider">
      {category.category_name && !isBanner && (
        <div>
          <div
            className="standardSliderTextMobile"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (category.category_name == "Film of the week") {
                history.push({
                  pathname: "/home/filmoftheweek",
                });
              } else {
                history.push({
                  pathname: "/home/categorylist",
                  search: encodeURI(
                    `category_id=${category.category_id}&category_name=${category.category_name}`
                  ),
                });
              }
            }}
          >
            {category.category_name}
          </div>
        </div>
      )}
      <div className="standardSliderWrapper">
        {category.category_name && !isBanner && (
          <div className="prevMask"></div>
        )}
        <div className="sliderTrack">
          <SlickSlider {...settings}>
            <div className="extra_item"></div>
            {path.startsWith("/movies")
              ? category.shows
                  .filter((item) => item.single_video == 1)
                  .map((show, index) => {
                    return (
                      <div key={index}>
                        <SliderItem
                          params={show}
                          clickHandler={clickHandler}
                          onClickTralier={onClickTralier}
                          categoryName={category.category_name}
                          categoryId={category.category_id}
                        />
                      </div>
                    );
                  })
              : path.startsWith("/tvshows")
              ? category.shows
                  .filter((item) => item.single_video == 0)
                  .map((show, index) => {
                    return (
                      <div key={index}>
                        <SliderItem
                          params={show}
                          clickHandler={clickHandler}
                          onClickTralier={onClickTralier}
                          categoryName={category.category_name}
                          categoryId={category.category_id}
                        />
                      </div>
                    );
                  })
              : category.shows.map((show, index) => {
                  return (
                    <div key={index}>
                      <SliderItem
                        params={show}
                        clickHandler={clickHandler}
                        onClickTralier={onClickTralier}
                        categoryName={category.category_name}
                        categoryId={category.category_id}
                      />
                    </div>
                  );
                })}
                {(category.count > category.shows.length)&&
            <div className="browseMoreStyle"
            onClick={() => {
              if (category.category_name == "Film of the week") {
                history.push({
                  pathname: "/home/filmoftheweek",
                });
              } else {
                history.push({
                  pathname: "/home/categorylist",
                  search: encodeURI(
                    `category_id=${category.category_id}&category_name=${category.category_name}`
                  ),
                });
              }
            }}
            ><span>+{category.count - category.shows.length}  more</span></div>
          }
          </SlickSlider>
        </div>
        {category.category_name && !isBanner && (
          <div className="standardSliderHead">
            <a>
              <div
                className="standardSliderText"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (category.category_name == "Film of the week") {
                    history.push({
                      pathname: "/home/filmoftheweek",
                    });
                  } else {
                    history.push({
                      pathname: "/home/categorylist",
                      search: encodeURI(
                        `category_id=${category.category_id}&category_name=${category.category_name}`
                      ),
                    });
                  }
                }}
              >
                {category.category_name}
              </div>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Slider;
