import React, { useState, useEffect } from "react";
import "./Header.css";
import { useHistory } from "react-router-dom";
import logo from "../../img/CaptureJWM.jpg";
import { useSelector, useDispatch } from "react-redux";
import { service } from "../../Network/service";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Modal from 'react-modal';

const Header = () => {
  const history = useHistory();
  const login = useSelector((state) => state.login);
  const [searchkey, setSearchkey] = useState("");
  const [mobilemenuClicked, setMobilemenuClicked] = useState(false);
  const [menuClicked, setMenuClicked] = useState(false);
  const [categoryBoxClick, setCategoryBoxClick] = useState(false);
  const [category, setCategory] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const closeModal = () => {
    setIsOpen(false);
  }

  const functionLogout = () => {
    let user = localStorage.getItem("userId");
    service.logoutFunction(user).then((response) => {
      if (response.success == true) {
        localStorage.removeItem("userName");
        localStorage.removeItem("userId");
        localStorage.removeItem("deviceAnalytics");
        service.setCookie("isLoggedIn", false, 30);
        localStorage.removeItem("isLoggedIn");
        service.eraseCookie("showId");
        service.eraseCookie("isScreeningUser");
        // dispatch({ type: "LOGOUT" });
        // setMouseHover(false);
        service.eraseCookie("userName");
        service.eraseCookie("userId");
        service.eraseCookie("userEmail");
        service.eraseCookie("subscriptionId");
        window.location.href = "/home";
      } else {
        console.log("somthing went wrong!");
      }
    });
  };

  const onSearchKeyChange = (e) => {
    e.preventDefault();
    const path = window.location.pathname;
    // if (!path.startsWith("/search")) {
    //   localStorage.setItem("previousPage", path);
    // }
    setSearchkey(e.target.value);
    if (e.target.value.trim().length > 0) {
      history.push({
        pathname: "/search",
        search: "?keyword=" + e.target.value.trim(),
      });
    } else {
      history.push("/home");
      // if (localStorage.getItem("previousPage"))
      //   history.push(localStorage.getItem("previousPage"));
    }
  };

  useEffect(() => {
    service.getshowsbyCategory().then((response) => {
      if (response.success === true && response.data.length > 0) {
        var data = response.data;
        setCategory(data);
      }
    });

    if(localStorage.getItem('isFirstTime') != 'false') {
      // setIsOpen(true)
      localStorage.setItem('isFirstTime', 'false')
    }


  }, []);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="work-guid-close" onClick={closeModal}>close</button>
        <video className="work-guid-player" controls autoPlay={true}>
          <source src="https://gizmeon.s.llnwi.net/wasabi/vod/PUB-50030/tutorial.mp4" type="video/mp4">
          </source></video>
      </Modal>
      <div
        className="header"
        style={{ position: "fixed", zIndex: "9999999", top: "0" }}
      >
        <div className="navigation">
          <div className="menuNav">
            <a href="/" className="logoLink">
              <div className="logoPlaceholder" style={{ cursor: "pointer" }}>
                <img src={logo} style={{ width: "125px" }} />
              </div>
            </a>
          </div>
          <div className="searchBar">
            <div>
              <form
                version="desktop"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="">
                  <div version="desktop" className="searchIcon">
                    <svg viewBox="0 0 512 512" fill="#7d7d7d">
                      <path d="m340 392l110 109 51-51-109-110z m-121 47c122 0 220-98 220-220 0-121-98-219-220-219-121 0-219 98-219 219 0 122 98 220 219 220z m0-73c-80 0-146-66-146-147 0-80 66-146 146-146 81 0 147 66 147 146 0 81-66 147-147 147z"></path>
                    </svg>
                  </div>
                  <input
                    name="query"
                    autoComplete="off"
                    placeholder="Search"
                    type="text"
                    version="desktop"
                    className="searchInput"
                    onChange={onSearchKeyChange}
                    value={searchkey}
                  />
                </div>
              </form>
            </div>
            <div className="css-1kzq5ms emk9x5r7"></div>
          </div>
          <div className="menuItems">
            <div
              className="live-button"
              style={{
                backgroundColor: "#001489",
                borderRadius: "5px",
                color: "white",
                padding: "1px 5px",
                textTransform: "uppercase",
                marginRight: "15px",
                letterSpacing: "1px",
                cursor: "pointer",
              }}
              onClick={(e) => {
                window.location.href = "/live";
              }}
            >
              Live
            </div>
            <div className="browse__tab" style={{ position: "relative" }}>
              <a
                // href="#"
                className="menuLinks"
                onClick={() => {
                  setMenuClicked(false);
                  setCategoryBoxClick(!categoryBoxClick);
                }}
              >
                Browse
              </a>
              {categoryBoxClick && (
                <div
                  onMouseLeave={() => {
                    setCategoryBoxClick(false);
                  }}
                >
                  <div className="categoryBox appear-exit-done">
                    <div className="menuClick__d__arrow"></div>
                    {category && category.length > 0 && (
                      <ul
                        className="menuClick__ul"
                        style={{ marginBottom: "0", paddingBottom: "0" }}
                      >
                        <li className="menuClick__ul__li categoryTitle">
                          <div
                            className="menuClick__ul__li__a"
                            onClick={() => {
                              history.push({
                                pathname: "/home",
                              });
                            }}
                          >
                            categories
                          </div>
                        </li>
                      </ul>
                    )}
                    <ul className="menuClick__ul categoryBox-overflowscroll">
                      {category &&
                        category.map((item, index) => {
                          return (
                            <li
                              className="menuClick__ul__li"
                              onClick={() => {
                                history.push({
                                  pathname: "/home/categorylist",
                                  search: encodeURI(
                                    `category_id=${item.category_id}&category_name=${item.category_name}`
                                  ),
                                });
                              }}
                            >
                              <div className="menuClick__ul__li__a">
                                {item.category_name}
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
            {login === true || localStorage.getItem("isLoggedIn") === "true" ? (
              <></>
            ) : (
              <div className="loginBox">
                <div
                  className="loginLink"
                  onClick={() => {
                    history.push("/signin");
                  }}
                >
                  Log In
                </div>
              </div>
            )}

            <div className="hamburgerDesktop">
              {login === true || localStorage.getItem("isLoggedIn") === "true" ? (
                <div
                  className="loginBox"
                  onClick={() => {
                    setMenuClicked(!menuClicked);
                    setCategoryBoxClick(false);
                  }}
                >
                  <div className="loginLink">
                    <AccountCircleIcon style={{ fontSize: "24px" }} />
                  </div>
                </div>
              ) : (
                <div className="menuContainer">
                  <div
                    className="hamburgerIcon"
                    onClick={() => {
                      setMenuClicked(!menuClicked);
                      setCategoryBoxClick(false);
                    }}
                  >
                    <svg viewBox="0 0 123.67 127" fill="#7d7d7d">
                      <rect height="26" rx="13" width="123.67"></rect>
                      <rect height="26" rx="13" width="123.67" y="50.5"></rect>
                      <rect height="26" rx="13" width="123.67" y="101"></rect>
                    </svg>
                  </div>
                </div>
              )}
              {menuClicked == true ? (
                <div
                  onMouseLeave={() => {
                    setMenuClicked(false);
                  }}
                >
                  <div className="menuClick appear-exit-done">
                    <div className="menuClick__d__arrow"></div>
                    <ul className="menuClick__ul">
                      {localStorage.getItem("isLoggedIn") === "true" && (
                        <li className="menuClick__ul__li">
                          <a href="/account" className="menuClick__ul__li__a">
                            Account Settings
                          </a>
                        </li>
                      )}
                      <li
                        className="menuClick__ul__li"
                        onClick={() => {
                          history.push({
                            pathname: "/home/categorylist",
                            search: encodeURI(
                              `category_id=${"playlist"}&category_name=${"Your Watchlist"}`
                            ),
                          });
                        }}
                      >
                        <a className="menuClick__ul__li__a">My Watch List</a>
                      </li>
                      {service.getCookie("isScreeningUser") == "true" && (
                        <li className="menuClick__ul__li">
                          <a
                            href="/screeningRoom"
                            className="menuClick__ul__li__a"
                          >
                            Screening room
                          </a>
                        </li>
                      )}
                      {localStorage.getItem("isLoggedIn") === "true" && (
                        <li className="css-45c27d"></li>
                      )}
                      <li className="menuClick__ul__li"><a href="#" onClick={() => { setIsOpen(true) }} className="menuClick__ul__li__a">How it works</a></li>
                      <li className="menuClick__ul__li">
                        <a href="/privacyPolicy" className="menuClick__ul__li__a">
                          Privacy Policy
                        </a>
                      </li>
                      <li className="menuClick__ul__li">
                        <a href="/termOfUse" className="menuClick__ul__li__a">
                          Terms of Use
                        </a>
                      </li>
                      <li className="menuClick__ul__li">
                        <a href="/aboutus" className="menuClick__ul__li__a">
                          About Us
                        </a>
                      </li>
                      {(login === true ||
                        localStorage.getItem("isLoggedIn") === "true") && (
                          <li
                            className="menuClick__ul__li"
                            onClick={functionLogout}
                          >
                            <a className="menuClick__ul__li__a">Log Out</a>
                          </li>
                        )}
                    </ul>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={
            mobilemenuClicked == true ? "fadeinEnter fadeinblack" : "fadeinEnter"
          }
          style={
            mobilemenuClicked == true
              ? { opacity: "1", display: "block", zIndex: "999999" }
              : { opacity: "1" }
          }
          onClick={() => {
            setMobilemenuClicked(false);
          }}
        ></div>
        <div
          className="mobileMenu"
          style={
            mobilemenuClicked == true
              ? {
                left: "auto",
                right: "30px",
                transition: "0.1s",
                zIndex: "9999999",
              }
              : { transition: "0.1s" }
          }
          onClick={() => {
            setMobilemenuClicked(!mobilemenuClicked);
          }}
        >
          <div id="nav-icon3" className={mobilemenuClicked == true ? "open" : ""}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div
          className="menu-overlay"
          style={
            mobilemenuClicked == true
              ? { left: "0", transition: "0.5s", zIndex: "9999999" }
              : { left: "-80%", transition: "0.5s" }
          }
        >
          <div className="css-16bwpud e1fu961i1">
            <div>
              <form
                version="mobile"
                onSubmit={(e) => {
                  e.preventDefault();
                  setMobilemenuClicked(false);
                }}
              >
                <div className="mobileSearchIconBox">
                  <div version="mobile" className="mobileSearchIcon">
                    <svg viewBox="0 0 512 512" fill="#7d7d7d">
                      <path d="m340 392l110 109 51-51-109-110z m-121 47c122 0 220-98 220-220 0-121-98-219-220-219-121 0-219 98-219 219 0 122 98 220 219 220z m0-73c-80 0-146-66-146-147 0-80 66-146 146-146 81 0 147 66 147 146 0 81-66 147-147 147z"></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    name="query"
                    autoComplete="off"
                    placeholder="Search"
                    version="mobile"
                    className="mobileSearchInput"
                    onChange={onSearchKeyChange}
                    value={searchkey}
                  />
                </div>
              </form>
            </div>
            <ul className="mobileMenuUL">
              <li>
                <a href="/home" className="mobileMenuLinks">
                  Home
                </a>
              </li>
              {/* <li>
              <a href="/movies" className="mobileMenuLinks">
                Movies
              </a>
            </li> */}
              {/* <li>
              <a href="/tvshows" className="mobileMenuLinks">
                TV shows
              </a>
            </li> */}
              <li>
                <a href="/live" className="mobileMenuLinks">
                  Live
                </a>
              </li>
              {localStorage.getItem("isLoggedIn") === "true" && (
                <li className="css-45c27d"></li>
              )}
              {(login === true ||
                localStorage.getItem("isLoggedIn") === "true") && (
                  <li>
                    <a href="/account" className="mobileMenuLinks">
                      Account Settings
                    </a>
                  </li>
                )}
              <li
                onClick={() => {
                  setMobilemenuClicked(false);
                  history.push({
                    pathname: "/home/categorylist",
                    search: encodeURI(
                      `category_id=${"playlist"}&category_name=${"Your Watchlist"}`
                    ),
                  });
                }}
              >
                <a
                  style={{ color: "#7d7d7d" }}
                  className="mobileMenuLinks ha__link"
                >
                  My Watch List
                </a>
              </li>
              {service.getCookie("isScreeningUser") == "true" && (
                <li>
                  <a
                    style={{ color: "#7d7d7d" }}
                    href="/screeningRoom"
                    className="mobileMenuLinks ha__link"
                  >
                    Screening room
                  </a>
                </li>
              )}
              <li className="css-45c27d"></li>
              <li><a className="mobileMenuLinks" onClick={() => { setIsOpen(true) }}>How it works</a></li>
              <li>
                <a href="/privacyPolicy" className="mobileMenuLinks">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/termOfUse" className="mobileMenuLinks">
                  Terms of Use
                </a>
              </li>
              <li>
                <a href="/aboutus" className="mobileMenuLinks">
                  About Us
                </a>
              </li>
              {login === true || localStorage.getItem("isLoggedIn") === "true" ? (
                <li onClick={functionLogout}>
                  <a
                    style={{ color: "#7d7d7d", cursor: "pointer" }}
                    className="mobileMenuLinks ha__link"
                  >
                    Log Out
                  </a>
                </li>
              ) : (
                <li>
                  <a href="/signin" className="mobileMenuLinks">
                    Log In
                  </a>
                </li>
              )}
            </ul>
            <div className="mobileMenuCloseWrapper">
              <div width="20px">
                <svg
                  viewBox="0 0 11 11"
                  stroke="white"
                  className="mobileMenuCloseLink"
                >
                  <path d="M1,1 L10,10"></path>
                  <path d="M10,1 L1,10"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div></>
  );
};

export default Header;
