import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Link, useHistory, useLocation } from "react-router-dom";
import SubscriptionContainer from "./SubscriptionContainer";
import { service } from "../../Network/service";
import "./Payment.css";
import { loadStripe } from "@stripe/stripe-js";
import stripeImage from "../../images/stripe.jpeg";
import paypalImage from "../../images/paypal2.png";
import mastercard from "../../img/mastercard.png";
import visa from "../../img/visa.png";
import american from "../../img/american.png";
import paypal from "../../img/payp.png";
import { ToastsContainer, ToastsStore } from "react-toasts";

// const stripePromise = loadStripe(
//   "pk_test_51JDFbeK25atJFkpQbw7O03taBw7JnpaWrWTSJxaXlF2xe3URtIp8Rugacvj4Xuz005cxSD6o1zUlpbhmzTFgL6nP00k2cMQ6lA"
// );

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);

// const stripePromise = loadStripe('pk_test_51Hn9UMFkgI4DLRDUgGco6HkS2onWNKtIpUYMG4beza7ze82gslIsPAKycBdHxUyyFgcPbNYjLhcLiaVf7orahIT500bYmsa6hV');
// const stripe = require("stripe")("sk_live_Viin86fYZCUN1SpmkxIaKwCL00NTkLD5Xo");
const Payment = (state) => {
  const history = useHistory();
  const isLoggedIn = service.getCookie("isLoggedIn");
  const [androidData, setAndroidData] = useState(null);
  const [isAndroid, setIsAndroid] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [paypalLink, setPaypalLink] = useState("");
  const [subscription, setSubscription] = useState([]);
  const [coupon, setCoupon] = useState("");
  const [applyCoupon, setApplyCoupon] = useState("");
  const [Invalid, setInvalid] = useState(false);
  const [notExist, setnotExist] = useState(false);
  const [success, setSuccess] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState(false);
  const [isCouponEnabled, setIsCouponEnabled] = useState(false);
  const [isStripeClick, setIsStripeClick] = useState(false);
  const [couponNotValid, setCouponNotValid] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (state.location.state) {
      setAndroidData(state.location.state.androidData);
      setPaymentData(state.location.state.paymentData);
      setIsAndroid(state.location.state.isAndroid);
      localStorage.setItem("deviceType", "web");
      if (state.location.state.isAndroid) {
        localStorage.setItem("isAndroid", state.location.state.isAndroid);
        localStorage.setItem("deviceType", "android-web");
      }
      let tempData = state.location.state.paymentData;
      let deviceType = localStorage.getItem("deviceType");
      let userId = service.getCookie("userId");
      let countryCode = service.getCookie("country_code");
      service.setCookie("subId", tempData.publisher_subscription_id, 10);
      localStorage.setItem("selectedSubId", tempData.publisher_subscription_id);
      var user_data = {
        transaction_type: 1,
        subscription_id: tempData.publisher_subscription_id,
        uid: userId,
        amount: tempData.price,
        pubid: 50030,
        country_code: countryCode,
        device_type: deviceType,
      };
      var user_details = encodeURIComponent(JSON.stringify(user_data));
      let link = `https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=${tempData.paypal_keyword}&custom=${user_details}&discount=10&currency_code=USD&upload=1`;
      // let link = `https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=${tempData.paypal_keyword}&custom=${user_details}&discount=10&currency_code=USD&upload=1`;
      setPaypalLink(link);
    } else if (isLoggedIn == "true") {
      history.push("/home");
    }
    var singleObj = [];
    let userId = service.getCookie("userId");
    service.userSubscription(userId).then((response) => {
      if (response.success == true) {
        if (response.forcibleLogout === true) {
          alert(
            "Sorry, You’ve reached the maximum Device limit. Please log in again!"
          );
          service.logoutAll(userId).then((res) => {
            setTimeout(() => {
              redirectToLogin();
            }, 1000);
          });
        } else if (response.session_expired === true) {
          ToastsStore.warning("Sorry! Session Has Expired");
          redirectToLogin();
        } else {
          var data = response.data;
          data.map((item, index) => {
            singleObj.push(item);
          });
          setSubscription(singleObj);
        }
      }
    });
  }, []);

  useEffect(() => {
    localStorage.removeItem("fromVideoplayer");
    localStorage.removeItem("coupon_id");
    localStorage.removeItem("selected_amount");
  }, []);

  const redirectToLogin = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    localStorage.removeItem("deviceAnalytics");
    service.setCookie("isLoggedIn", false, 30);
    localStorage.removeItem("isLoggedIn");
    service.eraseCookie("showId");
    service.eraseCookie("isScreeningUser");
    // dispatch({ type: "LOGOUT" });
    // setMouseHover(false);
    service.eraseCookie("userName");
    service.eraseCookie("userId");
    service.eraseCookie("userEmail");
    service.eraseCookie("subscriptionId");
    setTimeout(() => {
      let isAndroid = localStorage.getItem("isAndroid");
      if (isAndroid == true || isAndroid == "true") {
        window.location.href = "https://staging.justwatchme.tv/webviewfailed";
      } else {
        window.location.href = "/signin";
      }
    }, 500);
  };
  const handleCoupon = (e) => {
    setCoupon(e.target.value);
  };
  const applyCoupons = async () => {
    service.couponCode(coupon).then((response) => {
      if (response.data) {
        if (response.data.coupon_id) {
          let coupon_id = response.data.coupon_id;
          localStorage.setItem("coupon_id", coupon_id);
          setIsCouponEnabled(true);
          setSuccess(true);
          setnotExist(false);
          setInvalid(false);
          setCouponNotValid(false);
          setDiscountedPrice(true);
          setPaymentResponse(response.data.discounted_price);
          let selected_amount = response.data.discounted_price;
          localStorage.setItem("selected_amount", selected_amount);
        }
      } else if (response.message == "Coupon does not exist") {
        setnotExist(true);
        setSuccess(false);
        setInvalid(false);
        setCouponNotValid(false);
        setDiscountedPrice(false);
      } else if (
        response.message == "Coupon is not valid for this subscription."
      ) {
        setnotExist(false);
        setSuccess(false);
        setInvalid(false);
        setCouponNotValid(true);
        setDiscountedPrice(false);
      } else {
        setInvalid(true);
        setSuccess(false);
        setnotExist(false);
        setCouponNotValid(false);
        setDiscountedPrice(false);
      }
    });
  };
  const onClickPaypalButton = (link) => {
    if (coupon) {
      alert("Coupon Code is not applicable for PayPal!");
    } else {
      window.open(link, "_self");
    }
  };
  const handleClick = async (event) => {
    setIsStripeClick(true);
    localStorage.setItem(
      "selectedSubId",
      paymentData.publisher_subscription_id
    );
    localStorage.setItem("selectedAmount", paymentData.price);
    let coupon_id = localStorage.getItem("coupon_id");
    let session;
    if (isCouponEnabled == true) {
      session = await service.stripeSession(
        paymentData.publisher_subscription_id,
        coupon,
        coupon_id
      );
    } else {
      localStorage.removeItem("coupon_id");
      session = await service.stripeSession(
        paymentData.publisher_subscription_id
      );
    }
    const stripe = await stripePromise;
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };
  if (!isLoggedIn || isLoggedIn == "false") {
    return <Redirect push to="/signin" />;
  } else
    return (
      <div className="pageWrapper searchPageMain payment__container marginAdjust">
        <div className="topContainer">
          <div className="homepageWrapper menuCloseJS closeMenuWrapper">
            <div className="container">
              <p className="text-head">Payment Gateway</p>
              <div>
                <p
                  style={{
                    color: "white",
                    fontWeight: 500,
                    fontSize: "20px",
                    paddingLeft: "1%",
                  }}
                >
                  {" "}
                  Coupon Code :{" "}
                </p>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <input
                    className="coupon_input"
                    type="text"
                    value={coupon}
                    onChange={handleCoupon}
                  />
                  <button
                    onClick={applyCoupons}
                    style={{
                      height: "44px",
                      width: "90px",
                      fontSize: "15px",
                      marginLeft: "10px",
                      borderRadius: "5px",
                      backgroundColor: "orange",
                    }}
                    type="submit"
                  >
                    Apply
                  </button>
                </span>
                {success && (
                  <p style={{ color: "green" }}>
                    Coupon Code applied successfully
                  </p>
                )}
                {discountedPrice && (
                  <p style={{ color: "white" }}>
                    Grand Total : {paymentResponse}
                  </p>
                )}

                {notExist && (
                  <p style={{ color: "red" }}>Coupon Code does not exist</p>
                )}
                {couponNotValid && (
                  <p style={{ color: "red" }}>
                    Coupon is not valid for this subscription
                  </p>
                )}
                {Invalid && (
                  <p style={{ color: "red" }}>
                    Entered coupon code has expired
                  </p>
                )}
              </div>
              <fieldset className="myFieldSet">
                <legend className="payment-box">
                  Select a payment gateway
                </legend>
                <img
                  className="cards1"
                  style={{ marginLeft: "15px" }}
                  src={mastercard}
                  onClick={handleClick}
                ></img>
                <img
                  className="cards1"
                  style={{ marginLeft: "15px" }}
                  src={visa}
                  onClick={handleClick}
                ></img>
                <img
                  className="cards1"
                  style={{ marginLeft: "15px" }}
                  src={american}
                  onClick={handleClick}
                ></img>
                <a onClick={() => onClickPaypalButton(paypalLink)}>
                  <img className="cards1" src={paypal}></img>
                </a>
              </fieldset>
              <SubscriptionContainer param={subscription} />
            </div>
            <ToastsContainer store={ToastsStore} />
          </div>
        </div>
      </div>
    );
};
export default Payment;
