
const axios = require("axios");
const qs = require("querystring");

function getScreenUserViedio() {
    var token = localStorage.getItem("access-token");
    let ipaddress = getCookie("ipaddress");
    let deviceId = localStorage.getItem("deviceId");
    let uId = 74961;
    
    let countryCode = getCookie("country_code");
    
  
    let user_id = getCookie("userId");
    const customConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": true,
        crossorigin: true,
        "access-token": token,
        uid: uId,
        pubid: process.env.REACT_APP_PUBID,
        country_code: countryCode,
        channelid: process.env.REACT_APP_CHANNELID,
        dev_id: deviceId,
        ip: ipaddress,
        device_type: "web",
      },
    };
    if (user_id) {
         customConfig.headers['uid']=user_id
       }
      
    return axios
      .get(process.env.REACT_APP_API_URL + "show/screeningroom", customConfig)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return [];
      });
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
export const service = {
    getScreenUserViedio,
    getCookie
  };