import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import Home from "../components/Home/Home";
import Header from "../components/Basic/Header";
import Footer from "../components/Basic/Footer";
import Search from "../components/Search/Search";
import VideoDetails from "../components/VideoDetails/VideoDetails";
import SignIn from "../components/SignIn/SignIn";
import Register from "../components/Register/Register";
import SubscriptionList from "../components/SubscriptionList/SubscriptionList";
import Payment from "../components/Payment/Payment";
import Error from "../components/Payment/Error";
import Success from "../components/Payment/Success";
import Live from "../components/Live/Live";
import Account from "../components/Account/Account";
import ManageDevice from "../components/ManageDevice/ManageDevice";
import BilingActivity from "../components/BilingActivity/BilingActivity";
import Signout from "../components/Signout/Signout";
import ChangePassword from "../components/ChangePassword/changePassword";
import Activate from "../components/Activate/Activate";
import NotFound from "../components/NotFound/NotFound";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "../components/TermsOfUse/TermsOfUse";
import Support from "../components/Support/Support";
import CategoryList from "../components/CategoryList/CategoryList";
import AboutUs from "../components/AboutUs/AboutUs";
import FilmOfTheDay from "../components/NewArrivals/FilmOfTheDay";
// import ShowDetails from "../components/ShowDetails/ShowDetails";
import ScreeningRoom from "../components/ScreeningRoom/ScreeningRoom";
import ShowDetailsvanity from "../components/ShowDetails/ShowDetails";

const Routes = () => {
  const location = useLocation()
  const path = location.pathname;
  const isAndroid = useSelector((state) => state.isAndroid);
  return (
    <BrowserRouter>
      {!isAndroid && <Header />}
      <div
        className={location.pathname!=="/aboutus"?"closeMenuWrapper sideExs":"closeMenuWrapper"}
        id="scroll-top1"
        style={{ minHeight: "70vh", marginTop: "51px" }}
      >
        <Switch>
          <PublicRoutes path="/home" exact component={Home} />
          <Route path="/" exact component={Home} />
          <PublicRoutes
            path="/home/categorylist"
            exact
            component={CategoryList}
          />
          <PublicRoutes
            path="/home/filmoftheweek"
            exact
            component={FilmOfTheDay}
          />
          <PublicRoutes
            path="/movies/:showName"
            exact
            component={ShowDetailsvanity}
          />
          {/* <PublicRoutes path="/home/movies" exact component={ShowDetails} /> */}
          <PublicRoutes path="/movies" exact component={Home} />
          <PublicRoutes path="/tvShows" exact component={Home} />
          <PublicRoutes path="/live" exact component={Live} />
          <PublicRoutes path="/search" exact component={Search} />
          <PublicRoutes path="/videoDetails" exact component={VideoDetails} />
          <Route path="/register" exact component={Register} />
          <PublicRoutes path="/signIn" exact component={SignIn} />
          <PrivateRoutes path="/payment" exact component={Payment} />
          <PrivateRoutes path="/success" exact component={Success} />
          <PrivateRoutes path="/error" exact component={Error} />
          <PublicRoutes path="/termOfUse" exact component={TermsOfUse} />
          <PublicRoutes path="/privacyPolicy" exact component={PrivacyPolicy} />
          <PublicRoutes path="/contactsupport" exact component={Support} />
          <PrivateRoutes path="/account" exact component={Account} />
          <PrivateRoutes path="/manageDevice" exact component={ManageDevice} />
          <PrivateRoutes path="/signout" exact component={Signout} />
          <PrivateRoutes
            path="/screeningRoom"
            exact
            component={ScreeningRoom}
          />
          <PrivateRoutes
            path="/bilingActivity"
            exact
            component={BilingActivity}
          />
          <PrivateRoutes
            path="/changePassword"
            exact
            component={ChangePassword}
          />
          <Route path="/tv" exact component={Activate} />
          <PublicRoutes path="/aboutUs" exact component={AboutUs} />
          <PublicRoutes
            path="/SubscriptionList"
            exact
            component={SubscriptionList}
          />
          <Route path="/404" component={NotFound} /> <Redirect to="/404" />
        </Switch>
      </div>
      <Footer />
    </BrowserRouter>
  );
};
export default Routes;
