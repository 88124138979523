import React from "react";
import "./Tooltip.css";

const Tooltip = ({ title, position, style }) => {
  return (
    <div
      id="tooltip"
      className={position ? position : "left"}
      style={style ? style : {}}
    >
      <div className="tooltip-arrow" />
      <div className="tooltip-label">{title}</div>
    </div>
  );
};

export default Tooltip;
