import React, { useState, useEffect } from "react";
import "./FBanner.css";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { service } from "../../Network/service";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import TooltipMsg from "../Slider/Tooltip";

let url = "https://gizmeon.s.llnwi.net/vod/thumbnails/thumbnails/";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#fff",
    color: "#000",
    fontSize: "12px",
    boxShadow: "1px 1px 9px",
  },
  customArrow: {
    color: "#fff",
  },
}));

const FBanner = ({ show, clickHandler }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [watchListmsg, setWatchListmsg] = useState(false);



  const addtoMylistFunction = (show) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      service.addToMyPlayList(show.show_id, 1).then((response) => {
        clickHandler();
        setWatchListmsg("Added to watchlist");
        setTimeout(function () {
          setWatchListmsg(false);
        }, 1000);
      });
    } else {
      dispatch({ type: "SIGN_IN_BLOCK" });
    }
  };
  const removeFromMylistFunction = (show) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      service.addToMyPlayList(show.show_id, 0).then((response) => {
        clickHandler();
        setWatchListmsg("Removed from watchlist");
        setTimeout(function () {
          setWatchListmsg(false);
        }, 2000);
      });
    } else {
      dispatch({ type: "SIGN_IN_BLOCK" });
    }
  };
  return (
    <div className="individualBox" style={{ marginBottom: "20px" }}>
      <div className="moviePoster">
        <img src={url + show.logo_thumb} alt="" className="bannerImg" />
        <img
          src={url + show.logo_thumb}
          alt=""
          className="bannerImgMobile sidePadExs"
        />
        <div className="asOverlayContent">
          <div className="css-nnej1 e797jlf6 contentPad">
            <div>
              <div className="asHead">Film of the Week</div>
            </div>
            <div>
              <h2 className="asTitle">{show.show_name}</h2>
            </div>
            <div className="asContent">
              <h3 className="asMaker">
                <span className="asAuthor">Directed by {show.director} </span>
                <span className="css-r891bz ezubom41">{show.year}</span>
              </h3>
              <div className="asDescription">
                <p>{show.synopsis}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="hpBannerPlay">
          <div className="bcPlayButtonSize">
            <div className="blackOverlay">
              <a>
                <svg
                  style={{ width: "75px" }}
                  viewBox="0 0 44 44"
                  className="playSVG playSVG__mobile"
                  onClick={() => {
                    history.push({
                      pathname: `/movies/${show.vanity_url?.trim()}`,
                      // pathname: "/home/movies",
                      // search: encodeURI(`show_id=${show.show_id}`),
                    });
                  }}
                >
                  <g
                    transform="translate(1 1)"
                    fillRule="evenodd"
                    className="playG"
                  >
                    <circle
                      cx="21"
                      cy="21"
                      r="21"
                      className="playCircle"
                    ></circle>
                    <path
                      fill="#FFFFFF"
                      d="M30 21.5L15 29V14z"
                      className="playPath"
                    ></path>
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="watch__list__button">
          {show.watchlist_flag === 1 ? (
            <div>
              <Tooltip
                title="Remove from watchlist"
                arrow
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
              >
                <AddCircleOutlineIcon
                  style={{ fontSize: "32px", color: "white" }}
                  onClick={() => {
                    removeFromMylistFunction(show);
                  }}
                />
              </Tooltip>
              {watchListmsg == "Removed from watchlist" ? (
                <div style={{ position: "relative" }}>
                  <TooltipMsg title={watchListmsg} />
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              <Tooltip
                title="Add to watchlist"
                arrow
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
              >
                <AddCircleIcon
                  style={{ fontSize: "32px", color: "white" }}
                  onClick={() => {
                    addtoMylistFunction(show);
                  }}
                />
              </Tooltip>
              {watchListmsg == "Added to watchlist" ? (
                <div style={{ position: "relative" }}>
                  <TooltipMsg title={watchListmsg} />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FBanner;
