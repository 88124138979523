import React, { useState, useEffect } from "react";
import { service } from "../../Network/service";
import Carousel from "react-multi-carousel";
import { convertTimeToLocal, getDateStatus } from "../../Utils/utils";
import placeholderLogo from "../../img/placeholderLogo.jpg";
import "./Live.css";

var bannerShowUrl = "https://gizmeon.s.llnwi.net/vod/thumbnails/thumbnails/";

const LiveContainer = () => {
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    getLiveSchedule();

    setInterval(() => {
      getLiveSchedule();
    }, 60 * 1000);
  }, []);

  const getLiveSchedule = () => {
    service.getLiveChannels().then((response) => {
      if (response.data) {
        service
          .getLiveSchedule(response.data[0].channel_id)
          .then((response) => {
            setSchedule(response.data);
          });
      }
    });
  };
  const responsive = {
    superSuperLargeDesktop: {
      breakpoint: { max: 3000, min: 2200 },
      items: 6,
    },
    superLargeDesktop: {
      breakpoint: { max: 2200, min: 1850 },
      items: 5,
    },
    largeDesktop: {
      breakpoint: { max: 1850, min: 1300 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1300, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <section
      className="categoryWrapper"
      style={{ marginBottom: "50px", marginTop: "50px" }}
    >
      {schedule.length > 0 && (
        <div
          className="container categoryHeadWrapper"
          style={{ maxWidth: "80vw" }}
        >
          <section className="categoryWrapper">
            <div className="categoryLinkWrapper">
              <div className="categoryHeading" style={{ marginBottom: "30px" }}>
                <div className="_2hvCx">
                  <h2 className="_1mK3G" style={{ color: "#000" }}>
                    Streaming Guide
                  </h2>
                </div>
              </div>
            </div>
          </section>
          <div className="liveTvGuide">
            <div className="vpRightWrapper">
              <Carousel responsive={responsive}>
                {schedule.map((item, index) => {
                  return (
                    <section
                      //   style ={{marginRight : '40px'}}
                      className="movieTextWrapper vpRelatedMargin liveScheduleItem"
                      key={index}
                    >
                      <div
                        className="vpRelatedImage"
                        title={
                          item.video_description ? item.video_description : ""
                        }
                      >
                        <img
                          alt={item.video_title}
                          src={bannerShowUrl + item.thumbnail}
                          width="100%"
                          onError={(e) => {
                            e.target.src = placeholderLogo; 
                          }}
                        />
                        <div className="liveTvBlackOverlay"></div>
                      </div>
                      <div className="movieTextFlex">
                        <div className="movieCatYear movieCatYear1">
                          <div>
                            <div
                              className="movieCategory mcMargin live__period"
                              style={{ top: "28px" }}
                            >
                              <div style={{ color: "grey" }}>
                                Live from :&nbsp;
                                {item.starttime &&
                                  convertTimeToLocal(item.starttime)}
                              </div>
                            </div>
                            <div
                              className="movieCategory mcMargin live__period"
                              style={{ textTransform: "none" }}
                            >
                              <div style={{ color: "grey" }}>
                                Live to :&nbsp;
                                {item.endtime &&
                                  convertTimeToLocal(item.endtime)}
                              </div>
                            </div>
                            {/* <div className="movieCategory mcMargin webLivePeriod">
                              <div style={{ color: "grey" }}>
                                {item.starttime &&
                                  convertTimeToLocal(item.starttime)}{" "}
                                -{" "}
                                {item.endtime &&
                                  convertTimeToLocal(item.endtime)}
                              </div>
                            </div> */}
                            {/* {getDateStatus(item.starttime) && (
                              <div
                                className="linkButton movieTextHeading webLiveDate webLiveDate1"
                                style={{ color: "#000", fontWeight: "800" }}
                                title={item.video_title}
                              >
                                {getDateStatus(item.starttime)}
                              </div>
                            )} */}
                          </div>
                        </div>
                        <h3
                        // className="webLiveTitle"
                        >
                          {item.video_title && (
                            <div
                              className="linkButton movieTextHeading liveGuideTitle"
                              title={item.video_title}
                              // style={{
                              //   // marginRight: "40px",
                              //   color: "#000",
                              // }}
                            >
                              {item.video_title}
                              {/* {item.video_title != null &&
                              item.video_title.length > 25
                                ? item.video_title.substring(0, 20) + "..."
                                : item.video_title} */}
                            </div>
                          )}
                        </h3>
                      </div>
                    </section>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
export default LiveContainer;
