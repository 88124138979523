import React, { useState, useEffect } from "react";
import "./aboutUsStyle.css";
import bgImage from "../../images/about-banner.jpg";
import {ReactComponent as AppStoreIcon} from "../Icons/appStore.svg"
import {ReactComponent as PlayStoreIcon} from "../Icons/playStore.svg"

const AboutUs = () => {
  useEffect(() => {
    document.getElementById("scroll-top").scroll(0, 0);
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      // style={{
      //   fontSize: "16px",
      //   // height: "70vh",
      //   height: "585px",
      //   // backgroundColor: "#ff8d00e3",
      // }}
      className="aboutUsCont"
      style={{
        backgroundImage: `url("${bgImage}")`,
        height: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div
        className="pageWrapper"
       
      >
        {/* <div className="topContainer">
          <div className="menuCloseJS closeMenuWrapper">
            <div id="content" className="site-content">
              <div id="primary" className="content-area">
                <main
                  id="main"
                  className="site-main"
                  style={{ marginTop: "15rem", textAlign: "center" }}
                >
                  <article
                    id="post-39"
                    className="post-39 page type-page status-publish hentry"
                  >
                    <div className="entry-content">
                      <div className="vc_row wpb_row vc_row-fluid  bb_custom_1584031025503">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div className=" fantastic-header ml-3">
                                <div
                                  id="main-bg"
                                  className="main-bg"
                                  style={{ top: "0px" }}
                                ></div>
                                <div className="header-content">
                                  <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-10">
                                      <h1
                                        className="fade-in-text"
                                        style={{
                                          letterSpacing: "2px",
                                          lineHeight: "2",
                                          animation: "fadein2 1s",
                                          fontSize: "2em",
                                          fontWeight: "600",
                                          color: "#000000",
                                        }}
                                      >
                                        JustWatchMe.tv
                                      </h1>{" "}
                                      <p
                                        className="fade-in-text"
                                        style={{
                                          letterSpacing: "2px",
                                          lineHeight: "2",
                                          fontSize: "1.3em",
                                          color: "#000000",
                                        }}
                                      >
                                        JustWatchMe.tv is home to world class,
                                        independent cinema - entertaining and
                                        engaging worldwide audiences with an
                                        appetite for discovery.
                                        <a style={{ color: "#fff" }}> </a>
                                      </p>
                                    </div>
                                    <div className="col-md-1"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div> */}
        <div className="contents">
          <div className="left">
            <div className="logoContainer">
              <img src={require("../../images/JWMTV_final.png").default} alt="Logo" />
            </div>
          </div>
          <div className="right">
            <span className="link">
              <a href="https://justwatchme.tv/">JustWatchMe.tv</a>
            </span>
            <p>Movie’s you’ve just got to see!</p>
            <p>Home to world class, independent cinema - entertaining and engaging worldwide audiences with an appetite for discovery.</p>
            <div className="apps">
              <div className="imageContainer">
                <img src={require("../../images/fireTV2.jpg").default} alt="FireTv" onClick={()=>window.open("https://www.amazon.com/Capital-Motion-Picture-Group-Inc/dp/B09G6TJTG7","_blank")} />
              </div>
              <div className="imageContainer">
                <img src={require("../../images/roku.jpg").default} alt="Roku" onClick={()=>window.open("https://channelstore.roku.com/details/bbb7e9cc133ef10afd6f14748ac0695d","_blank")} />
              </div>
              <div className="imageContainer svg" onClick={()=>window.open("https://apps.apple.com/in/app/justwatchme-tv/id1577160113","_blank")}>
                <AppStoreIcon/>
              </div>
              <div className="imageContainer svg" onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.justwatchme.android","_blank")}>
                <PlayStoreIcon/>
              </div>
              
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
