import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { service } from "../../Network/service";
import { useDispatch } from "react-redux";
const Success = () => {
  const history = useHistory();
  const [isSucess, setIsSucces] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [subscription, setSubscription] = useState([]);
  const [fromVP, setFromVP] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let prevurl = localStorage.getItem("fromVideoplayer");
    if (prevurl == "true") {
      // const vshowId = service.getCookie("showId");
      const showTitle = service.getCookie("showTitle");
      if (showTitle) {
        history.push({
          pathname: `/movies/${showTitle?.trim()}`,
          // pathname: "/home/movies",
          // search: encodeURI(`show_id=${vshowId}`),
        });
      } else {
        history.push({
          pathname: "/home",
        });
      }
    } else {
      setFromVP(true);
    }

    let isAndroid = localStorage.getItem("isAndroid");
    if (isAndroid == "true" || isAndroid == true) {
      dispatch({ type: "SET_ANDROID" });
    }
    var urlParams = new URLSearchParams(window.location.search);
    var sessionId = urlParams.get("session_id");
    // let paypalData = urlParams.get("amt");  // live
    let paypalData = urlParams.get("token")
      ? urlParams.get("token")
      : urlParams.get("amt"); // sandbox
    if (sessionId) {
      service.stripeDecode(sessionId).then((response) => {
        if (response.success != false) {
          let subscription = response.data.subscription;
          service
            .paymentUpdate(subscription, "stripe", "success")
            .then((response) => {
              if (response.status == 200) {
                localStorage.removeItem("coupon_id");
                let isAndroid = localStorage.getItem("isAndroid");
                if (isAndroid == "true") {
                  window.location.href =
                    "https://staging.justwatchme.tv/webviewsuccess"; // android success redirect
                } else {
                  setIsLoading(false);
                  setIsSucces(true);
                }
              } else {
                service
                  .paymentUpdate(subscription, "stripe", "failed")
                  .then((response) => {
                    if (response.status == 201) {
                      localStorage.removeItem("coupon_id");
                      let isAndroid = localStorage.getItem("isAndroid");
                      if (isAndroid == "true") {
                        window.location.href =
                          "https://staging.justwatchme.tv/webviewsuccess"; // android error redirect
                      } else {
                        setIsLoading(false);
                        setIsSucces(true);
                      }
                    } else if (response.status == 200) {
                      let isAndroid = localStorage.getItem("isAndroid");
                      if (isAndroid == "true") {
                        window.location.href =
                          "https://staging.justwatchme.tv/webviewfailed"; // android error redirect
                      } else {
                        history.push("/error");
                      }
                    }
                  });
              }
            });
        } else {
          let isAndroid = localStorage.getItem("isAndroid");
          if (isAndroid == "true") {
            window.location.href =
              "https://staging.justwatchme.tv/webviewfailed"; // android error redirect
          } else {
            history.push("/error");
          }
        }
      });
    } else if (paypalData) {
      let subID = localStorage.getItem("selectedSubId");
      var myInterval = setInterval(async () => {
        const result = await service
          .paypalSubscription(subID)
          .then((response) => {
            if (response.status == 201) {
              clearInterval(myInterval);
              let isAndroid = localStorage.getItem("isAndroid");
              if (isAndroid == "true") {
                window.location.href =
                  "https://staging.justwatchme.tv/webviewsuccess"; // android succes redirect
              } else {
                setIsLoading(false);
                setIsSucces(true);
              }
            }
          });
      }, 10 * 1000);
    }
  }, []);
  const handleClick = () => {
    
    let showId = service.getCookie("showId");
    let showTitle = service.getCookie("showTitle");
    let videoId = service.getCookie("videoId");
    let showDetails;
    let videoDetails;
    let video;
    console.clear();
    if (showTitle && videoId) {
      service.getShowDetails(showTitle).then((response) => {
        if (response.success == true && response.data) {
          showDetails = response.data;
          videoDetails = response.data.videos;
          video = videoDetails.filter((e) => e.video_id == videoId);
          if (video && video.length > 0) {
            history.push({
              pathname: "/videoDetails",
              state: { movie: video[0], show_id: showId, prevUrl: "/success" },
            });
          }
        }
      });
    } else if (showTitle) {
      history.replace({
        pathname: `/movies/${showTitle?.trim()}`,
        // pathname: "/home/movies",
        // search: encodeURI(`show_id=${showId}`),
      });
    } else {
      history.replace({
        pathname: "/home",
      });
    }
  };
  return (
    <div className="pageWrapper searchPageMain marginAdjust">
      {fromVP == true ? (
        <div className="topContainer" style={{ minHeight: "71vh" }}>
          <div className="homepageWrapper menuCloseJS closeMenuWrapper">
            <div className="container" style={{ marginTop: "90px" }}>
              <div className="card" style={{ width: "fit-content" }}>
                {isLoading && (
                  <div className="mycontainer">
                    <div
                      className="modal-dialog modal-confirm"
                      style={{ marginTop: "0" }}
                    >
                      <div className="modal-content">
                        <div className="modal-body">
                          <div className="d-flex align-items-center">
                            <strong>
                              Your payment will be updated soon....{" "}
                            </strong>
                            <div
                              className="spinner-border ml-auto"
                              role="status"
                              aria-hidden="true"
                              style={{ color: "#219cb2" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isSucess && (
                  <div className="mycontainer">
                    <div className="modal-dialog modal-confirm">
                      <div className="modal-content">
                        <div className="modal-header">
                          <div className="icon-box">
                            <i className="material-icons">&#xE876;</i>
                          </div>
                          <h4 className="modal-title w-100">Success!</h4>
                        </div>
                        <div className="modal-body">
                          <p className="text-center">
                            Your payment has been confirmed. Enjoy the videos.
                          </p>
                        </div>
                        <div className="modal-footer">
                          <button
                            className="myBtn1 btn-success btn-block"
                            onClick={handleClick}
                            data-dismiss="modal"
                          >
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
export default Success;
