import React, { useState, useEffect } from "react";
import { service } from "../../Network/service";
import ReactHlsPlayer from "react-hls-player";
import "react-multi-carousel/lib/styles.css";
import placeholderLogo from "../../img/placeholderLogo.jpg";
import { convertTimeToLocal, ssaiAdParam } from "../../Utils/utils";
var liveThumbnailUrl = "https://gizmeon.s.llnwi.net/vod/thumbnails/thumbnails/";
var pause = false;

const LiveContainer = () => {
  const [channels, setChannels] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [logo, setLogo] = useState("");
  const [videoPlayer, setVideoPlayer] = useState();
  const [isVideoPause, setIsVideoPause] = useState(true);
  const [livePlayer, setLivePlayer] = useState(
    <video
      id="content_video"
      className="video-js vjs-default-skin mainPlayer"
      controls
      preload="auto"
    >
      {" "}
      <source src="" type="video/mp4" />{" "}
    </video>
  );

  useEffect(() => {
    service.getLiveChannels().then((response) => {
      setChannels(response.data[0]);

      if (response.data[0].now_playing.thumbnail) {
        setLogo(response.data[0].now_playing.thumbnail);
      }

      if (response.data[0].live_link) {
        "start_time" in response.data[0].now_playing
          ? setStartTime(response.data[0].now_playing.start_time)
          : setStartTime("");
        "end_time" in response.data[0].now_playing
          ? setEndTime(response.data[0].now_playing.end_time)
          : setEndTime("");
        "video_title" in response.data[0].now_playing
          ? setVideoTitle(response.data[0].now_playing.video_title)
          : setVideoTitle("");

        let liveUrl = response.data[0].live_link;
        setLivePlayer(
          <video
            // id={videoElem}
            id="singleVideo"
            className="video-js vjs-default-skin mainPlayer"
            controls
            preload="auto"
            autoPlay
          >
            <source src={liveUrl} type="application/x-mpegURL" />
          </video>
        );
        ssaiAdParam(response.data[0]).then((params) => {
          window.playLivePlayer("singleVideo", response.data[0], params);
        });

        // setVideoPlayer(
        //   // <ReactHlsPlayer
        //   //   id="singleVideo"
        //   //   url={response.data[0].live_link}
        //   //   autoplay={true}
        //   //   controls={true}
        //   //   width={"100%"}
        //   //   height={"100%"}
        //   // />
        //   ssaiAdParam(response.data[0]).then((params) => {
        //     window.playLivePlayer("singleVideo", response.data[0], params);
        //   })
        // );
      }
    });
    // window.addEventListener("scroll", handleScroll);
setInterval(() => {
  service.getLiveChannels().then((response) => {
    setChannels(response.data[0]);

    if (response.data[0].now_playing.thumbnail) {
      setLogo(response.data[0].now_playing.thumbnail);
    }

    if (response.data[0].live_link) {
      "start_time" in response.data[0].now_playing
        ? setStartTime(response.data[0].now_playing.start_time)
        : setStartTime("");
      "end_time" in response.data[0].now_playing
        ? setEndTime(response.data[0].now_playing.end_time)
        : setEndTime("");
      "video_title" in response.data[0].now_playing
        ? setVideoTitle(response.data[0].now_playing.video_title)
        : setVideoTitle("");
    }
  });
}, 60 * 1000);

  }, []);
  window.onLiveVideoPlay = (vd) => {
    let event = "POP03";
    service.onVideoPlayFunction(vd, event).then((response) => {});
  };
  window.onLiveVideoPause = (vd) => {
    let event = "POP04";
    setIsVideoPause(false);
    pause = true;
    service.onVideoPlayFunction(vd, event).then((response) => {});
  };
  window.onLiveVideoEnd = (vd) => {
    let event = "POP05";
    service.onVideoPlayFunction(vd, event).then((response) => {});
  };
  return (
    <div className="entireBanner">
      <div className="hpLiveBanner">
        <div className="liveVideoWrapper">
          {livePlayer}
          {/* {videoPlayer && (
             <video id="singleVideo" className="video-js vjs-default-skin mainPlayer"
             controls preload="auto"
             autoPlay >
         </video>
          )} */}
          {startTime && endTime ? (
            <div className="hpWrapperVideo">
              <section className="movieTextWrapper vpRelatedMargin">
                <div className="vpRelatedImage" style={{ flex: "0" }}>
                  <img
                    alt={videoTitle}
                    src={liveThumbnailUrl + logo }
                    style={{ width: "58px", objectFit: "contain" }}
                    onError={(e) => {
                      e.target.src = placeholderLogo; 
                    }}
                  />
                  <div className="liveTvBlackOverlay"></div>
                  <div className="liveTvPlay"></div>
                </div>
                <div className="movieTextFlex">
                  <div className="movieCatYear">
                    <div>
                      <div className="movieCategory mcMargin">
                        <div>
                          {startTime && convertTimeToLocal(startTime)}
                          {startTime && "-"}{" "}
                          {endTime && convertTimeToLocal(endTime)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3>
                    <div
                      className="linkButton movieTextHeading"
                      title={videoTitle}
                    >
                      {videoTitle}
                    </div>
                  </h3>
                </div>
              </section>
            </div>
          ) : null}
        </div>
        <div className="overlayTiles"></div>
      </div>
    </div>
  );
};
export default LiveContainer;
