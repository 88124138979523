import React, { useState, useEffect } from "react";
import "./SliderWithBanner.css";
import { useHistory } from "react-router-dom";
import Slider from "../Slider/Slider";

var showsImageUrl = "https://gizmeon.s.llnwi.net/vod/thumbnails/show_logo/";

const SliderWithBanner = (params) => {
  const history = useHistory();
  return (
    <div className="bgImageSection sidePadExs">
      <div className="bgImageSectionWrapper">
        <a href="#" className="css-1gn6nfl elojzy510"></a>
        <div
          className="bgImagePlaceholder"
          style={{
            backgroundImage: `url("${showsImageUrl + params.category.banner}")`,
          }}
        >
          <div className="bgImageSectionGradient">
            <div className="bgImageSectiontext">
              <div className="bgImgSectionHeading">
                <div
                  style={params.category.synopsis ? {} : { marginTop: "10%" }}
                  onClick={() => {
                    history.push({
                      pathname: "/home/categorylist",
                      search: encodeURI(
                        `category_id=${params.category.category_id}&category_name=${params.category.category_name}`
                      ),
                    });
                  }}
                >
                  <h2 className="asTitle" style={{ cursor: "pointer" }}>
                    {params.category.category_name}
                  </h2>
                </div>
              </div>
              <div>
                {params.category.synopsis && (
                  <div className="bgImageSectionContent">
                    <p
                      className="bgImageSectionContentPara"
                      title={params.category.synopsis}
                    >
                      {params.category.synopsis}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bgImageSlider">
          <Slider
            category={params.category}
            clickHandler={params.clickHandler}
            onClickTralier={params.onClickTralier}
            isBanner={true}
          />
        </div>
      </div>
    </div>
  );
};

export default SliderWithBanner;
