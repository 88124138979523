export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const SHOW_ID = 'SHOW_ID';
export const ADD_TO_MY_LIST = 'ADD_TO_MY_LIST';
export const SIGN_IN_BLOCK = 'SIGN_IN_BLOCK';
export const SET_CURRENT_EXPAND = 'SET_CURRENT_EXPAND';
export const SET_PRV_EXPAND = 'SET_PRV_EXPAND';
export const UPDATE_MENU = 'UPDATE_MENU';
export const SET_ANDROID = 'SET_ANDROID';
export const USERNAME = "USERNAME";
