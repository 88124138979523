import React, { useState, useEffect } from "react";
import FacebookLogin from "react-facebook-login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { service } from "./service";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Redirect, Link } from "react-router-dom";
import { clippingParents } from "@popperjs/core";

const Register = (state) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const showId = useSelector((state) => state.showId);

  const [faceBookDatas, setFacebookDatas] = useState({
    facebookId: "",
    facebookEmail: "",
  });

  const [otherValues, setotherValues] = useState({
    firstname: "",
    email: "",
    password: "",
    password2: "",
    userRegisterId: "",
    verification_code: "",
    msgError: "",
    msgErrorVerify: "",
    msgSuccess: "",
  });

  const [flag, setFlag] = useState({
    isRegister: true,
    isFbAcive: true,
    isSuccessMsg: true,
    isErrorMsg: false,
    isErrorVerifyMsg: false,
    isEmailExistMsg: false,
    isFirstNameSelected: false,
    isEmailSelected: false,
    isPasswordSelected: false,
    isconfirmPasswordSelected: false,
    isVerifyEmailSelected: false,
  });

  const [values, setValues] = useState({
    firstname: "",
    email: "",
    password: "",
    password2: "",
  });
  const [errors, setErrors] = useState({
    firstname: "Name",
    email: "Email",
    password: "Password",
    password2: "Confirm Password",
  });
  const [valuesVerify, setValuesVerify] = useState({
    verification_code: "",
  });
  const [errorsVerify, setErrorsVerify] = useState({
    verification_code: "Verification Code",
  });
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [eye1, setEye1] = useState(<FontAwesomeIcon icon={faEye} />);
  const [eye2, setEye2] = useState(<FontAwesomeIcon icon={faEye} />);
  const [isEye1, setIsEye1] = useState(false);
  const [isEye2, setIsEye2] = useState(false);
  const togglePasswordVisiblity1 = () => {
    setPasswordShown1(passwordShown1 ? false : true);
    setEye1(
      passwordShown1 ? (
        <FontAwesomeIcon icon={faEye} />
      ) : (
        <FontAwesomeIcon icon={faEyeSlash} />
      )
    );
  };
  const togglePasswordVisiblity2 = () => {
    setPasswordShown2(passwordShown2 ? false : true);
    setEye2(
      passwordShown2 ? (
        <FontAwesomeIcon icon={faEye} />
      ) : (
        <FontAwesomeIcon icon={faEyeSlash} />
      )
    );
  };
  let FBData = null;
  useEffect(() => {
    window.scrollTo(0, 0);
    window.scrollTo(0, 0);

    if (state.location.state) {
      let FBData = state.location.state.facebookData;

      setFlag({ ...flag, isFbAcive: false });

      setFacebookDatas({ ...faceBookDatas, facebookId: FBData.id });

      if (FBData.email) {
        setValues({
          ...values,
          ["firstname"]: FBData.first_name,
          ["email"]: FBData.email,
        });
      } else {
        setValues({
          ...values,
          ["firstname"]: FBData.first_name,
        });
      }
    }
  }, []);
  const validateEmail = (email) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    }
    return false;
  };
  const validatePassword = (password) => {
    if (/^[a-zA-Z0-9@#$%&*^!.,+\-:;_\'\"?]{6,30}$/.test(password)) {
      return true;
    }
    return false;
  };
  const validateVerify = (verification_code) => {
    if (/^\d*$/.test(verification_code.trim())) {
      return true;
    }
    return false;
  };
  const validateName = (firstname) => {
    if (/^[a-zA-Z ]*$/.test(firstname.trim())) {
      return true;
    }
    return false;
  };
  const onChangeHandler = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    if (name == "password") {
      if (value.length > 0) {
        setIsEye1(true);
      } else {
        setIsEye1(false);
      }
    } else if (name == "password2") {
      if (value.length > 0) {
        setIsEye2(true);
      } else {
        setIsEye2(false);
      }
    }
  };
  const onChangeHandlerVerify = (e) => {
    const { name, value } = e.target;

    setValuesVerify({
      ...valuesVerify,
      [name]: value,
    });
  };

  const validationVerify = () => {
    let errorsVerify = {};
    let formIsValid = true;
    if (valuesVerify.verification_code.trim()) {
      var errorMsg = validateVerify(valuesVerify.verification_code);
      if (errorMsg === true) {
        errorsVerify.verification_code = "Verification Code";

        setotherValues({ ...otherValues, verification_code: "" });
      } else {
        formIsValid = false;

        setotherValues({
          ...otherValues,
          verification_code: " Input--errored",
        });
        errorsVerify.verification_code = "OTP contain numeric only";
      }
    } else {
      formIsValid = false;
      errorsVerify.verification_code = "Required Verification Field";

      setotherValues({ ...otherValues, verification_code: " Input--errored" });
    }
    setErrorsVerify(errorsVerify);
    return formIsValid;
  };
  const validation = () => {
    let errors = {};
    let formIsValid = true;

    var newError = [];

    if (values.firstname.trim()) {
      var errorMsg = validateName(values.firstname);
      if (errorMsg === true) {
        errors.firstname = "Name";
        newError[0] = "";
        setotherValues({ ...otherValues, firstname: "" });
      } else {
        formIsValid = false;
        newError[0] = " Input--errored";
        setotherValues({ ...otherValues, firstname: " Input--errored" });
        errors.firstname = "Alphabets only";
      }
    } else {
      formIsValid = false;
      newError[0] = " Input--errored";
      errors.firstname = "Required Name Field";

      setotherValues({ ...otherValues, firstname: " Input--errored" });
    }
    if (values.email.trim()) {
      var errorMsg = validateEmail(values.email);
      if (errorMsg === true) {
        errors.email = "Email";

        newError[1] = "";
        setotherValues({ ...otherValues, email: "" });
      } else {
        formIsValid = false;

        newError[1] = " Input--errored";
        setotherValues({ ...otherValues, email: " Input--errored" });
        errors.email = "Invalid email Address";
      }
    } else {
      formIsValid = false;
      errors.email = "Required Email Field";
      newError[1] = " Input--errored";
      setotherValues({ ...otherValues, email: " Input--errored" });
    }

    if (values.password) {
      var errorMsg = validatePassword(values.password);
      if (errorMsg === true) {
        errors.password = "Password";
        newError[2] = "";
        setotherValues({
          ...otherValues,
          password2: " Input--errored",
          password: newError[2],
          firstname: newError[0],
          email: newError[1],
        });
      } else {
        formIsValid = false;
        newError[2] = " Input--errored";

        setotherValues({
          ...otherValues,
          password2: " Input--errored",
          password: newError[2],
          firstname: newError[0],
          email: newError[1],
        });
        errors.password = "Invalid password";
      }
    } else {
      formIsValid = false;
      newError[2] = " Input--errored";

      setotherValues({
        ...otherValues,
        password2: " Input--errored",
        password: newError[2],
        firstname: newError[0],
        email: newError[1],
      });
      errors.password = "Required Password Field";
    }

    if (values.password2) {
      var errorMsg = validatePassword(values.password2);
      if (values.password2.trim() !== values.password.trim()) {
        formIsValid = false;

        setotherValues({
          ...otherValues,
          password2: " Input--errored",
          password: newError[2],
          firstname: newError[0],
          email: newError[1],
        });

        errors.password2 = "Confirm Password mismatch";
      } else if (errorMsg === true) {
        errors.password2 = "Confirm Password";

        setotherValues({ ...otherValues, password2: "" });
      } else if (errorMsg !== true) {
        formIsValid = false;

        setotherValues({ ...otherValues, password2: " Input--errored" });
        errors.password2 = "Invalid password";
      }
    } else {
      formIsValid = false;
      setotherValues({
        ...otherValues,
        password2: " Input--errored",
        password: newError[2],
        firstname: newError[0],
        email: newError[1],
      });

      errors.password2 = "Required confirm Password Field";
    }

    if (
      !values.firstname?.trim() &&
      !values.email?.trim() &&
      !values.password?.trim() &&
      !values.password1?.trim()
    ) {
      formIsValid = false;

      errors.firstname = "Required Name Field";
      errors.email = "Required Email Field";

      errors.password2 = "Required confirm Password Field";
      errors.password = "Required Password Field";
      setotherValues({
        ...otherValues,
        email: " Input--errored",
        firstname: " Input--errored",
        password: " Input--errored",
        password2: " Input--errored",
      });
    }

    setErrors(errors);
    return formIsValid;
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (validation()) {
      service.register(values, faceBookDatas.facebookId).then((response) => {
        if (response.success == true) {
          setotherValues({
            ...otherValues,
            userRegisterId: response.user_id,
            msgSuccess:
              "OTP sent to your Email Id (Note:  If you do not find the email in your inbox, please check your spam filter or bulk email folder)",
          });

          setFlag({ ...flag, isSuccessMsg: true, isRegister: false });

          setTimeout(function () {
            setFlag({ ...flag, isSuccessMsg: false, isRegister: false });
          }, 5000);
        } else if (response.status == 0) {
          setotherValues({
            ...otherValues,
            msgError: "There was an error during registration",
          });

          setFlag({ ...flag, isErrorMsg: true });
          setTimeout(function () {
            setFlag({ ...flag, isErrorMsg: false });
          }, 5000);
        } else {
          setotherValues({
            ...otherValues,
            msgError: "Already registered user",
          });

          setFlag({ ...flag, isErrorMsg: true });

          setTimeout(function () {
            setFlag({ ...flag, isErrorMsg: false });
          }, 5000);
        }
      });
    }
  };
  const onVerifyHandler = (e) => {
    e.preventDefault();
    if (validationVerify()) {
      service
        .verifyEmail(valuesVerify, otherValues.userRegisterId)
        .then((response) => {
          if (response.success == true) {
            localStorage.setItem("isLoggedIn", true);
            service.setCookie("userId", response.data[0].user_id, 30);
            localStorage.setItem("userId", response.data[0].user_id);
            localStorage.setItem("userName", response.data[0].first_name);
            service.setCookie("isLoggedIn", "true", 30);

            setotherValues({
              ...otherValues,
              msgSuccess: "You are successfully registered",
            });

            setFlag({ ...flag, isSuccessMsg: true });
            setTimeout(function () {
              setFlag({ ...flag, isSuccessMsg: false });
            }, 5000);
            dispatch({ type: "LOGIN", payload: true });
            if (sessionStorage.getItem("tvActivateFlag") == "true") {
              history.push("/tv");
            } else {
              window.location.href = "/home";
              // if (showId != "") {
              //   history.push({
              //     pathname: "/home/movies",
              //     search: encodeURI(`show_id=${showId}`),
              //   });
              // } else {
              //   window.location.href = "/home";
              // }
            }
          } else {
            setotherValues({ ...otherValues, msgErrorVerify: "Invalid OTP" });

            setFlag({ ...flag, isErrorVerifyMsg: true });
            setTimeout(function () {
              setFlag({ ...flag, isErrorVerifyMsg: false });
            }, 5000);
          }
        });
    }
  };
  const responseFacebook = (response) => {
    FBData = response;
    setFacebookDatas({ ...faceBookDatas, facebookId: FBData.id });

    setFacebookDatas({ ...faceBookDatas, facebookEmail: FBData.email });

    if (response.id != undefined && response.id != null && response.id != "")
      service
        .facebookLogin(
          response.id,
          response.email,
          response.first_name,
          response.last_name
        )
        .then((response) => {
          if (response.status == 200) {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("userName", response.data[0].first_name);
            service.setCookie("userId", response.data[0].user_id, 30);
            localStorage.setItem("userId", response.data[0].user_id);
            service
              .userSubscription(response.data[0].user_id)
              .then((response) => {
                service.setCookie("isLoggedIn", "true", 30);
                var user_sub = response.data;
                if (user_sub.length > 0) {
                  service.setCookie("isLoggedIn", "true", 30);
                  setotherValues({
                    ...otherValues,
                    msgSuccess: "You are successfully registered",
                  });

                  setFlag({ ...flag, isSuccessMsg: true });
                  setTimeout(function () {
                    setFlag({ ...flag, isSuccessMsg: false });
                  }, 5000);
                  history.push("/home");
                  if (sessionStorage.getItem("tvActivateFlag") == "true") {
                    history.push("/tv");
                  } else {
                    //window.location.href = "/home";
                  }
                } else {
                  service.setCookie("isLoggedIn", "true", 30);
                  setotherValues({
                    ...otherValues,
                    msgSuccess: "You are successfully registered",
                  });

                  setFlag({ ...flag, isSuccessMsg: true });
                  setTimeout(function () {
                    setFlag({ ...flag, isSuccessMsg: false });
                  }, 5000);
                  history.push("/home");
                  if (sessionStorage.getItem("tvActivateFlag") == "true") {
                    history.push("/tv");
                  } else {
                    // window.location.href = "/home";
                  }
                }
              });
          } else if (response.status == 202) {
            setotherValues({
              ...otherValues,
              msgError: "There was an error during registration",
            });

            setFlag({ ...flag, isErrorMsg: true });

            setTimeout(function () {
              setFlag({ ...flag, isErrorMsg: false });
            }, 5000);
          } else if (response.status == 203) {
            setFlag({ ...flag, isFbAcive: false });

            if (FBData.email) {
              setValues({
                ...values,
                ["firstname"]: FBData.first_name,
                ["email"]: FBData.email,
              });
            } else {
              setValues({
                ...values,
                ["firstname"]: FBData.first_name,
              });
            }
          } else if (response.status == 201) {
            setotherValues({
              ...otherValues,
              userRegisterId: response.data[0].user_id,
            });

            setFlag({ ...flag, isRegister: false });
            setotherValues({
              ...otherValues,
              msgSuccess:
                "OTP sent to your Email Id (Note:  If you do not find the email in your inbox, please check your spam filter or bulk email folder)",
            });

            setFlag({ ...flag, isSuccessMsg: true });
            setTimeout(function () {
              setFlag({ ...flag, isSuccessMsg: false });
            }, 5000);
          } else if (response.status == 204) {
            confirmAlert({
              closeOnEscape: false,
              closeOnClickOutside: false,
              message: "Do you want to link your Facebook account?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => onFBLink(),
                },
                {
                  label: "No",
                  onClick: () => onFBNoLink(),
                },
              ],
            });
          }
        });
  };
  const onFBLink = () => {
    service
      .facebookLink(
        FBData.id,
        FBData.email,
        FBData.first_name,
        FBData.last_name
      )
      .then((response) => {
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("userName", response.data[0].first_name);
        service.setCookie("userId", response.data[0].user_id, 30);
        localStorage.setItem("userId", response.data[0].user_id);
        service.userSubscription(response.data[0].user_id).then((response) => {
          service.setCookie("isLoggedIn", "true", 30);
          var user_sub = response.data;
          if (user_sub.length > 0) {
            service.setCookie("isLoggedIn", "true", 30);
            setotherValues({
              ...otherValues,
              msgSuccess: "You are successfully registered",
            });

            setFlag({ ...flag, isSuccessMsg: true });
            setTimeout(function () {
              setFlag({ ...flag, isSuccessMsg: false });
            }, 5000);
            history.push("/home");
            if (sessionStorage.getItem("tvActivateFlag") == "true") {
              history.push("/tv");
            } else {
              window.location.href = "/home";
            }
          } else {
            service.setCookie("isLoggedIn", "true", 30);
            setotherValues({
              ...otherValues,
              msgSuccess: "You are successfully registered",
            });

            setFlag({ ...flag, isSuccessMsg: true });
            setTimeout(function () {
              setFlag({ ...flag, isSuccessMsg: false });
            }, 5000);
            history.push("/home");
            if (sessionStorage.getItem("tvActivateFlag") == "true") {
              history.push("/tv");
            } else {
              window.location.href = "/home";
            }
          }
        });
      });
  };
  const onFBNoLink = () => {
    setFlag({ ...flag, isEmailExistMsg: true });
    setTimeout(function () {
      setFlag({ ...flag, isEmailExistMsg: false });
    }, 5000);
  };
  const onSignIn = () => {
    history.push("/signin");
  };
  let isLoggedIn = localStorage.getItem("isLoggedIn");

  if (isLoggedIn === "true") {
    return <Redirect to="/home" />;
  }
  return (
    <div
      className="pageWrapper searchPageMain leftpadd"
      style={{ background: "#fff" }}
    >
      <div className="topContainer">
        <div
          className={`menuCloseJS closeMenuWrapper ${
            flag.isRegister ? "regnPage" : "regnPage1"
          }`}
        >
          <div className="container">
            <div
              className="row regnWrapper"
              style={{ marginRight: "0", marginLeft: "0" }}
            >
              <div className="col col-9 col-lg-6 col-xl-6 col-xxl-5">
                <h3 className="H3">Let's get you set up!! </h3>
                <div>
                  {flag.isFbAcive && (
                    <div>
                      <div rel="noopener" target="_self">
                        <button
                          className="button buttonLarge buttonBlock registerFacebook"
                          style={{ marginLeft: "0" }}
                        >
                          <div className="buttonBg"></div>
                          <FacebookLogin
                            style={{ marginLeft: "0" }}
                            appId="1347318108996478"
                            fields="name,email,picture,first_name"
                            callback={responseFacebook}
                            cssClass="button buttonLarge buttonBlock registerFacebook"
                            isMobile={false}
                            textButton="Register via Facebook"
                          />
                        </button>
                      </div>
                      <div className="orContainer orMargin">
                        <div className="orDivider"></div>
                        <div className="orCircle">
                          <div className="orText">OR</div>
                        </div>
                        <div className="orDivider"></div>
                      </div>
                    </div>
                  )}

                  {flag.isRegister ? (
                    <div id="registerId">
                      <h5 className="H5 regnFormHeading">Register via email</h5>
                      <form
                        className="regnformContainer"
                        noValidate
                        onSubmit={onSubmitHandler}
                      >
                        {flag.isErrorMsg && (
                          <p className="_3nmo_">{otherValues.msgError}</p>
                        )}
                        {flag.isEmailExistMsg && (
                          <p className="_3nmo_">
                            {faceBookDatas.facebookEmail} already exist,
                            Please&nbsp;&nbsp;
                            <button
                              onClick={onSignIn}
                              className="linkButton button buttonSmall"
                            >
                              <div
                                className="buttonBg"
                                style={{ backgroundColor: "#fbcd37" }}
                              ></div>
                              <div className="buttonContent">Sign In</div>
                            </button>
                          </p>
                        )}
                        <div
                          style={{ marginTop: "18px" }}
                          onClick={() =>
                            setFlag({ ...flag, isFirstNameSelected: true })
                          }
                          onBlur={() =>
                            setFlag({ ...flag, isFirstNameSelected: false })
                          }
                          className={`input ${otherValues.firstname} ${
                            flag.isFirstNameSelected ? "inputActive" : ""
                          }`}
                        >
                          <input
                            className="inputText"
                            style={{
                              border: "none",
                              padding: "0px",
                              marginTop: "10px",
                            }}
                            name="firstname"
                            type="text"
                            maxLength="60"
                            value={values.firstname}
                            onChange={onChangeHandler}
                          />
                          <span className="inputLabel" style={(errors.firstname === 'Name')?{} :{color:'red'}}>{errors.firstname}</span>
                        </div>
                        <div
                          style={{ marginTop: "22px" }}
                          onClick={() =>
                            setFlag({ ...flag, isEmailSelected: true })
                          }
                          onBlur={() =>
                            setFlag({ ...flag, isEmailSelected: false })
                          }
                          className={`input ${otherValues.email} ${
                            flag.isEmailSelected ? "inputActive" : ""
                          }`}
                        >
                          <input
                            className="inputText"
                            style={{
                              border: "none",
                              padding: "0px",
                              marginTop: "10px",
                            }}
                            name="email"
                            type="email"
                            value={values.email}
                            onChange={onChangeHandler}
                          />
                          <span className="inputLabel">{errors.email}</span>
                          {!values.email && (
                            <span className="inputHint">
                              We never share this
                            </span>
                          )}
                        </div>
                        <div
                          style={{ marginTop: "20px" }}
                          onClick={() =>
                            setFlag({ ...flag, isPasswordSelected: true })
                          }
                          onBlur={() =>
                            setFlag({ ...flag, isPasswordSelected: false })
                          }
                          className={`input ${otherValues.password} ${
                            flag.isPasswordSelected ? "inputActive" : ""
                          }`}
                        >
                          <input
                            className="inputText"
                            title="Length must be between 6 and 30"
                            style={{
                              border: "none",
                              padding: "0px",
                              marginTop: "10px",
                            }}
                            name="password"
                            type={passwordShown1 ? "text" : "password"}
                            value={values.password}
                            onChange={onChangeHandler}
                          />
                          {isEye1 && (
                            <i
                              className="eyeIcon"
                              onClick={togglePasswordVisiblity1}
                            >
                              {eye1}
                            </i>
                          )}

                          <span className="inputLabel">{errors.password}</span>
                          {!values.password && (
                            <span className="inputHint">
                              Pick something you can remember
                            </span>
                          )}
                        </div>

                        <div
                          style={{ marginTop: "20px" }}
                          onClick={() =>
                            setFlag({
                              ...flag,
                              isconfirmPasswordSelected: true,
                            })
                          }
                          onBlur={() =>
                            setFlag({
                              ...flag,
                              isconfirmPasswordSelected: false,
                            })
                          }
                          className={`input ${otherValues.password2} ${
                            flag.isconfirmPasswordSelected ? "inputActive" : ""
                          }`}
                        >
                          <input
                            className="inputText"
                            title=""
                            style={{
                              border: "none",
                              padding: "0px",
                              marginTop: "10px",
                            }}
                            name="password2"
                            type={passwordShown2 ? "text" : "Confirm password"}
                            value={values.password2}
                            onChange={onChangeHandler}
                          />
                          {isEye2 && (
                            <i
                              className="eyeIcon"
                              onClick={togglePasswordVisiblity2}
                            >
                              {eye2}
                            </i>
                          )}

                          <span className="inputLabel">{errors.password2}</span>
                          {!values.password2 && (
                            <span className="inputHint">
                              {/* Pick something you can remember */}
                            </span>
                          )}
                        </div>

                        <div className="regnSubmitWrapper">
                          <p
                            style={{
                              paddingTop: "10px",
                              fontSize: "14px",
                              color: "rgb(112, 124, 134)",
                            }}
                          >
                            Already have an account?
                            <Link to={{ pathname: "/signin" }}>
                              <span className="linkButton">&nbsp; Sign In</span>
                            </Link>
                          </p>
                          <button
                            className="button buttonLarge regnSubmit"
                            type="submit"
                            id="registerSubmit"
                          >
                            <div
                              className="buttonBg"
                              style={{ backgroundColor: "#fbcd37" }}
                            ></div>
                            <div className="buttonContent">Register</div>
                          </button>
                        </div>
                        <div className="regnAgreeContent">
                          <p>
                            By registering, you agree to JustWatchMe.tv
                            <Link to="/termOfUse">
                              <div className="linkButton">
                                &nbsp;Terms of Use
                              </div>
                            </Link>
                            &nbsp; and
                            <Link to="/privacyPolicy">
                              <div className="linkButton" href="#">
                                &nbsp;Privacy Policy
                              </div>
                            </Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <div id="verifyId">
                      <h5
                        className="H5 regnFormHeading"
                        style={{ marginTop: "0px", marginBottom: "25px" }}
                      >
                        Email Verification
                      </h5>
                      <form
                        className="regnformContainer"
                        noValidate
                        onSubmit={onVerifyHandler}
                      >
                        {flag.isSuccessMsg && (
                          <p className="_3nmo_success">
                            {otherValues.msgSuccess}
                          </p>
                        )}
                        {flag.isErrorVerifyMsg && (
                          <p className="_3nmo_">{otherValues.msgErrorVerify}</p>
                        )}
                        <div
                          onClick={() =>
                            setFlag({ ...flag, isVerifyEmailSelected: true })
                          }
                          onBlur={() =>
                            setFlag({ ...flag, isVerifyEmailSelected: false })
                          }
                          className={`input ${otherValues.verification_code} ${
                            flag.isVerifyEmailSelected ? "inputActive" : ""
                          }`}
                        >
                          <input
                            className="inputText"
                            style={{
                              border: "none",
                              padding: "0px",
                              marginTop: "10px",
                            }}
                            name="verification_code"
                            type="text"
                            maxLength="60"
                            value={valuesVerify.verification_code}
                            onChange={onChangeHandlerVerify}
                          />
                          <span className="inputLabel">
                            {errorsVerify.verification_code}
                          </span>
                        </div>
                        <div className="regnSubmitWrapper">
                          <button
                            className="button buttonLarge regnSubmit"
                            type="submit"
                          >
                            <div
                              className="buttonBg"
                              style={{ backgroundColor: "#fbcd37" }}
                            ></div>
                            <div className="buttonContent">Verify</div>
                          </button>
                        </div>
                      </form>
                      <div className="regnAgreeContent">
                        <p>
                          By registering, you agree to JustWatchMe.tv
                          <Link to="/termOfUse">
                            <div className="linkButton">
                              {" "}
                              &nbsp;Terms of Use
                            </div>
                          </Link>
                          &nbsp; and
                          <Link to="/privacyPolicy">
                            <div className="linkButton" href="#">
                              {" "}
                              &nbsp;Privacy Policy
                            </div>
                          </Link>
                        </p>
                        <p>
                          Already have an account?
                          <Link to={{ pathname: "/signin" }}>
                            <span className="linkButton">&nbsp; Sign In</span>
                          </Link>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Register;
