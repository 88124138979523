import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./Privacy.css";

const WebPrivacyPolicy = () => {
  const dispatch = useDispatch();
  let urlParams = new URLSearchParams(window.location.search);
  const [pContainer, setPContainer] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    let fromMobile = urlParams.get("fromMobile");
    if (fromMobile && fromMobile == "true") {
      dispatch({ type: "SET_ANDROID" });
      setPContainer({ ...pContainer, marginTop: "-51px" });
    }
  }, []);
  return (
    <div>
      <div
        className="menuCloseJS closeMenuWrapper leftpadd"
        style={Object.keys(pContainer).length === 0 ? {} : pContainer}
      >
        <div className="privacyTermsFlex privacyTermsColor privacyTermsScroll">
          <div className="privacyTermsWidth" style={{ marginTop: "-80px" }}>
            <div className="headDiv">
              <h1 className="privacyTermsHeading">Privacy Policy</h1>
            </div>

            <div>
              <div className="privacyTermsContent">
                <p>
                  When you use this Website (“Website”), applications (“Apps”)
                  or services (individually or together, the “Services”), you
                  consent to provide us with information which enables us to
                  provide you with a unique user experience. By using the
                  Services, you consent to our collection, storage, processing
                  and transfer of your personal information or sensitive
                  personal data or information in accordance with this privacy
                  policy.
                </p>
                <p>
                  You may at any time opt to change your information, as well as
                  to manage your communication preferences from the Website or
                  Apps.
                </p>

                <h4 className="subHeading subHeading_font-style">
                  1. Introduction
                </h4>
                <p>
                  JustWatchMe.tv (“Our Platform” or “we” or “our”) is operated
                  by Capital Motion Picture Group Inc., 343 Preston Street,
                  Suite 1100, Ottawa, ON K1S 1N4, in compliance with existing
                  rules and laws governing user privacy in Canada. How we handle
                  your information, and your rights in respect of this
                  information is explained below.
                </p>
                <p>
                  This privacy policy should be read together with the Terms of
                  Use. User’s personal information is collected when that user
                  registers with us, accesses or takes any action on the Website
                  or uses the services as defined in the Terms of Use. The terms
                  ‘personal information’, ‘sensitive personal data’ and
                  ‘information’ shall have the meaning ascribed to it under the
                  applicable governing laws of Canada.
                </p>

                <h5 className="subHeading">
                  2. Collection and Use of Information
                </h5>

                <ol className="listposition">
                  <li>
                    Personal information may include registration details
                    including your name, email address, contact details, age,
                    gender, password, location, pin code, area code, occupation,
                    billing, tax registration and other information pertaining
                    to your transactions with the Services.
                  </li>
                  <li>
                    For a better experience while using our Service, we may
                    require you to provide us with certain personally
                    identifiable information, including but not limited to your
                    name, email address, phone number, and postal address. The
                    information that we collect will be used to contact or
                    identify you, including allowing us to provide you
                    meaningful customer support. If you would like to request
                    modification or deletion of certain personally identifiable
                    information, please contact us so that we will be able to do
                    necessary actions.
                  </li>
                  <li>
                    Debit or credit card or other bank account details while
                    accessing any paid services offered as part of the Services.
                  </li>
                  <li>
                    Your comments, ratings or content that you publicly post or
                    share on the Services.
                  </li>
                  <li>
                    User id or user name, or any information you have permitted
                    a social media service to share with us, and any information
                    you have made public with the social media service.
                  </li>
                  <li>
                    Content usage and behaviour, adverts served, purchasing
                    behaviour, other traceable activities online.
                  </li>
                  <li>
                    Details of the operating system or network used to undertake
                    any activity on the Services, such as a unique device
                    identifier including user behaviour from web based cookies,
                    session identifiers from device id, commercially available
                    services, data aggregator platforms.
                  </li>
                  <li>User preferences (e.g., preferred language settings);</li>
                  <li>
                    IP address, operating system, browser type, browser version,
                    browser configuration, name of internet service provider,
                    and other types of computer and connection related
                    information relevant to identifying your type of device,
                    connecting to the Services, enabling data exchange with you
                    and your device, and ensuring a convenient use of the
                    Services.
                  </li>
                  <li>
                    The uniform resource locator (URL) and IP address of the
                    Website from which you accessed, or were directed to our
                    Services, including date and time.
                  </li>
                  <li>
                    Subpages visited while using our Services, links followed,
                    including date and time.
                  </li>
                  <li>
                    The full URL clickstream to, through and from the Services,
                    including date and time.
                  </li>
                  <li>Service requests and orders placed.</li>
                  <li>
                    Transaction history, including open and completed
                    transactions.
                  </li>
                  <li>Search terms entered.</li>
                  <li>
                    Services/products viewed or searched for on the Services.
                  </li>
                  <li>Shopping cart and payment information.</li>
                  <li>Credit card information.</li>
                  <li>
                    Information collected by cookies or similar technologies (as
                    described in the next paragraph).
                  </li>
                  <li>
                    Newsletter subscriptions, enrollment for promotions, use of
                    special offers, etc.
                  </li>
                  <li>Consents, authorizations, etc. granted.</li>
                  <li>
                    Survey answers, reviews, ratings and other types of feedback
                    provided.
                  </li>
                  <li>
                    Content of any communication sent through the Services,
                    including any information posted in social communities on
                    the Services or otherwise shared with platform and/or other
                    users, as well as chat messages and chat transcripts.
                  </li>
                  <li>Information on software downloaded from the Services</li>
                  <li>
                    Any other information that you enter on, or upload to, the
                    Services (e.g., content that you fill into an online form, a
                    photo that you upload).
                  </li>
                </ol>

                <p>
                  The primary purpose of collecting the above forms of
                  information and personal data from you is to allow you to use
                  the features provided by the Services or their affiliates,
                  service providers or business partners and advertisers to use
                  and record your information as permitted by law in order to
                  serve promotional and advertising materials to you and such
                  other uses as provided in the Terms of Use and this privacy
                  policy.
                </p>
                <p>
                  We may also use this information as provided by for content
                  recommendations, transactional emails or to provide
                  information, direct marketing, online and offline advertising
                  and other materials regarding products, services and other
                  offers from time to time in connection with the Services,
                  parent, subsidiary and affiliated companies ("Platform
                  Entities").
                </p>
                <p>
                  This information may also be used track user behavior and
                  preferences for internal analytics of all users of the
                  Services, and to: (i) to evaluate your eligibility for certain
                  types of offers, products or services (ii) to perform
                  analytics and conduct customer research, including general
                  market research or surveying our customers' needs and opinions
                  on specific issues, generating sales and traffic patterns, and
                  to analyze advertising effectiveness, both on an anonymous
                  basis (e.g., by aggregating data) or on an individual basis
                  (if legally permissible).
                </p>
                <p>
                  The Services integrate certain third party software
                  development kits (SDKs) within the platform to assist in
                  placing advertisements better suited to you and improve the
                  overall user experience. Such third party SDKS may directly
                  collect certain personal information from you on the platform,
                  such as user behavior, preferences, account activity, IP
                  address, user's interactions with advertisements and
                  identifiers for advertisers (IDFAs) and Google Advertising id
                  (AAID).
                </p>
                <p>
                  This personal information or sensitive personal data or
                  information is collected through the use of server log files
                  and tracking technologies to collect and analyze certain types
                  of technical information and may include cookies and web
                  beacons. If you do not consent to the use of cookies and/or
                  device session tracking by the platform, you may at any time
                  adjust your browser settings and/or delete and/or disable the
                  cookies. However, please note that should you choose to
                  delete/ disable the use of cookies or other tracking
                  technologies on your computer, certain features of the
                  Services may not be available to you or may provide a
                  diminished user experience.
                </p>
                <p>
                  We may aggregate this information with other information
                  collected from other users using the Services to provide you
                  with improved experiences whenever possible.
                </p>
                <p>
                  You understand, agree and acknowledge that our collection,
                  storage and processing of your personal information or
                  sensitive personal data or information is for a lawful purpose
                  connected with a function or activity of the Platform Entities
                  and its joint ventures. YOU HEREBY CONSENT TO THE COLLECTION,
                  STORAGE, USE, DISCLOSURE, TRANSFER, DELETION OF THE PERSONAL
                  INFORMATION OR SENSITIVE PERSONAL DATA OR INFORMATION BY
                  PLATFORM FOR THE PURPOSES SET OUT IN THIS PRIVACY POLICY. You
                  further understand, agree and acknowledge that your personal
                  information which is classified as sensitive personal data or
                  information as per applicable laws is considered necessary for
                  the platform to provide various services on its Website to you
                  and for your usage of the Website and other services provided
                  by platform in relation to the Website and you affirmatively
                  consent to its collection, storage, transfer and processing.
                </p>

                <h5 className="subHeading">
                  3. Information sharing and disclosure
                </h5>
                <p>
                  Our Platform will exercise reasonable measures to prevent the
                  unauthorized disclosure and deletion of personal information.
                  We will endeavour to obligate third parties to whom we provide
                  access to personal information to be under contractual
                  obligation to protect personal information in accordance with
                  the same standard as the Platform, however, these third
                  parties may change their information collection policies
                  without our consent. You acknowledge and expressly consent to
                  our enabling access to personal information to the Platform
                  Entities, joint ventures, business partners, agents or third
                  parties for the purposes of the services provided by them or
                  for any other marketing related activity undertaken by or on
                  behalf of the Platform Entities and/or its joint ventures,
                  and/or for the purposes of enhancing the quality of the
                  services provided to you, or to complete commercial
                  transactions within the Services. Such third parties may be
                  situated where applicable data protection laws provide a
                  lesser degree of protection than your home country.
                </p>

                <h5 className="subHeading">
                  4. Compliance with laws and law enforcement
                </h5>
                <p>
                  The Platform will cooperate with government and law
                  enforcement agencies and may be required to disclose personal
                  information without your permission when we believe it is
                  necessary or appropriate in response to valid claims, legal
                  process, or to prevent or stop any illegal or unethical
                  activity. Formal complaints will be considered through
                  internal resolution processes and we will use our best
                  endeavours to respond with a decision within 30 days.
                </p>

                <h5 className="subHeading">5. Business transfers</h5>
                <p>
                  Our Platform may transfer or share its assets, including
                  personal information in connection with a merger, acquisition,
                  reorganization or sale of assets or in the event of bankruptcy
                  and you consent to the same. In any such instance, we will
                  endeavor for the transferee to manage personal information in
                  a manner that is consistent with this privacy policy.
                </p>

                <h5 className="subHeading">6. Email policies</h5>
                <p>
                  You have full control regarding which emails you want to
                  receive. If you decide not to receive email communications
                  from the Platform Entities, then please follow the unsubscribe
                  instructions provided.
                </p>

                <h5 className="subHeading">7. Data security</h5>
                <p>
                  The Platform will strive to protect personal information
                  against potential risks and exposures, however, absolute
                  security is not available in the online sphere. You can help
                  us protecting your personal data by signing off when you
                  finish using login-protected areas of the Services, changing
                  your password regularly, and avoiding the use of an automatic
                  log on.
                </p>

                <h5 className="subHeading">8. Deleting your information</h5>
                <p>
                  If you would like to have your personal information deleted,
                  please send a request to us via our customer services. Please
                  note than any deletion will result in your access being
                  restricted to the Services
                </p>

                <h5 className="subHeading">
                  9. Links to other sites / financial data
                </h5>
                <p>
                  Links are provided to other Websites and portals such as third
                  party payment gateway providers for your convenience and
                  information (“Other Websites”). This includes third party
                  payment gateway providers to complete payment of your
                  subscription fee. The Platform does not directly collect any
                  financial information such as credit card or debit card or net
                  banking details. Other Websites may change their privacy
                  policy at any time, so when you visit them, you do so at your
                  own risk. Other Websites may have their own privacy policies
                  in place, which should be reviewed prior to use and we assume
                  no liability for their privacy practices, nor endorse them.
                </p>
                <h5 className="subHeading">10. Children's Privacy</h5>
                <p>
                  Our Services strictly comply with the Children’s Online
                  Privacy Protection Act (“COPPA”). We do not knowingly collect
                  personal identifiable information from children under 13. In
                  the case we discover that a child under 13 has provided us
                  with personal information, we immediately delete this from our
                  servers. If you are a parent or guardian and you are aware
                  that your child has provided us with personal information,
                  please contact us so that we will be able to do necessary
                  actions.
                </p>

                <h5 className="subHeading">11. Security</h5>
                <p>
                  Our platform uses secure server software (ssl) to encrypt all
                  information you submit before it is sent to the Platform. The
                  transmission of personal information over the internet cannot
                  be guaranteed as secure. To prevent unauthorized access and
                  ensure correct use of personal information, we will endeavour
                  to employ reasonable security practices and procedures and to
                  ensure that any third party that we employ complies with the
                  same security standards as the Platform
                </p>
                <p>
                  In the event any incident of breach may occur, we will
                  promptly notify and immediately begin remediation under
                  applicable laws and regulations.
                </p>

                <h5 className="subHeading">12. Changes in privacy policy</h5>
                <p>
                  Your continued use of our Website and Services constitutes
                  your acceptance of the privacy policy and terms of use in
                  effect at the time of use, and these polices may be updated
                  from time to time.
                </p>

                <h5 className="subHeading">13. Phishing</h5>
                <p>
                  We will NEVER request your credit card, national
                  identification numbers or any sensitive information from you
                  in a non-secure or unsolicited email or telephone. We take
                  identity theft and the practice currently known as "phishing"
                  very seriously.
                </p>

                <h5 className="subHeading">14. Contact us</h5>
                <p>
                  Please contact us at any time with question about this privacy
                  policy or other concerns:
                  <span
                    style={{ textDecoration: "underline", color: "blue" }}
                    onClick={(e) => {
                      window.open("mailto:support@justwatchme.tv");
                    }}
                  >
                    support@justwatchme.tv
                  </span>
                  .
                </p>

                <p>
                  JustWatchMe.tv &copy; 2021 All Rights Reserved. Capital Motion
                  Picture Group Inc. - Canada.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WebPrivacyPolicy;
