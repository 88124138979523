import React, { useState, useEffect } from "react";
import AwardsFestivals from "./AwardsFestivals";
import BannerHead from "./BannerHead";
import Cast from "./Cast";
import ShowCategory from "./ShowCategory";
import KeyArticles from "./KeyArticles";
import { service } from "./service";
import { useLocation, useHistory, useParams } from "react-router-dom";
import ShowEpisode from "./ShowEpisode";

import { ToastsContainer, ToastsStore } from "react-toasts";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const queryString = require("query-string");

function ShowDetails() {
  var { search } = useLocation();
  const parsed = queryString.parse(search);
  const history = useHistory();
  const [apiRes, setApiRes] = useState("");
  const [locationKeys, setLocationKeys] = useState([]);
  const { showName } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("scroll-top").scroll(0, 0);
    if (showName) {
      // let show_id = parsed.show_id;
      service.setCookie("showTitle", showName, 10);
      service.getShowDetailByTitle(showName).then((response) => {
        if (response.success == true && response.data) {
          setApiRes(response.data);
        } else {
          history.push("/404");
        }
      });
    } else {
      history.replace("/home");
    }
  }, []);

  const freeVideoPopup = (onClickYes, onClickNo) => {
    confirmAlert({
      title: "",
      message: "Want to watch ad-free?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            onClickYes();
          },
        },
        {
          label: "No",
          onClick: () => {
            onClickNo();
          },
        },
      ],
    });
  };

  const onPlayVideo = (e) => {
    
    e.preventDefault();
    let user_id = service.getCookie("userId");
    if (user_id == null || user_id == service.getCookie("guestUserId")) {
      history.push({
        pathname: "/signin",
      });
    } else {
      if (
        apiRes &&
        apiRes.videos &&
        apiRes.videos[0] &&
        apiRes.videos[0].screening_enabled != true && // video with subscriptions
        apiRes.videos[0].free_video != true
      ) {
        let movie = apiRes.videos[0];
        service.videoSubscription(movie.video_id).then((response) => {
          let videoDetails = response.data;
          let subFlag = true;
          let uId = service.getCookie("guestUserId");
          let user_id = service.getCookie("userId");
          if (user_id) {
            uId = user_id;
          }
          service.userSubscription(uId).then((useResponse) => {
            var userData = useResponse.data;

            if (useResponse.success == true) {
              if (useResponse.forcibleLogout === true) {
                alert(
                  "Sorry, You’ve reached the maximum Device limit. Please log in again!"
                );
                service.logoutAll(uId).then((res) => {
                  setTimeout(() => {
                    redirectToLogin();
                  }, 1000);
                });
              } else if (useResponse.session_expired === true) {
                ToastsStore.warning("Sorry! Session Has Expired");
                redirectToLogin();
              } else {
                videoDetails.map(function (subscription, index) {
                  if (useResponse.data.length == 0 && subFlag) {
                    subFlag = false;
                    service.setCookie("showId", apiRes.show_id, 10);
                    service.setCookie("videoId", movie.video_id, 10);
                    history.push({
                      pathname: "/SubscriptionList",
                      state: {
                        videoData: movie.video_id,
                      },
                    });
                  } else {
                    let subscribedVideo = userData.filter(
                      (e) => e.sub_id == subscription.publisher_subscription_id
                    );
                    if (
                      subscribedVideo.length == 0 &&
                      index + 1 < videoDetails.length
                    ) {
                      return;
                    }
                    if (
                      subscribedVideo.length == 0 &&
                      subFlag &&
                      index + 1 == videoDetails.length
                    ) {
                      subFlag = false;
                      service.setCookie("showId", apiRes.show_id, 10);
                      service.setCookie("videoId", movie.video_id, 10);
                      history.push({
                        pathname: "/SubscriptionList",
                        state: {
                          videoData: movie.video_id,
                        },
                      });
                    } else if (subFlag) {
                      subFlag = false;
                      service.setCookie("showId", apiRes.show_id, 10);
                      localStorage.setItem("ContinueWatching", "true");
                      history.push({
                        pathname: "/videoDetails",
                        state: { movie: movie, show_id: apiRes.show_id },
                      });
                    }
                  }
                });
              }
            }
          });
        });
      } else {
        if (
          apiRes &&
          apiRes.videos &&
          apiRes.videos[0] &&
          apiRes.videos[0].screening_enabled == true //screening room video
        ) {
          let movie = apiRes.videos[0];
          service.setCookie("showId", apiRes.show_id, 10);
          localStorage.setItem("ContinueWatching", "true");
          history.push({
            pathname: "/videoDetails",
            state: { movie: movie, show_id: apiRes.show_id },
          });
        } else if (
          apiRes &&
          apiRes.videos &&
          apiRes.videos[0] &&
          apiRes.videos[0].free_video == true &&
          apiRes.videos[0].subscriptions &&
          apiRes.videos[0].subscriptions.length > 0 //free video  but have subscriptions
        ) {
          let uId = service.getCookie("guestUserId");
          let user_id = service.getCookie("userId");
          if (user_id) {
            uId = user_id;
          }
          let movie = apiRes.videos[0];
          const onClickNo = () => {
            service.setCookie("showId", apiRes.show_id, 10);
            localStorage.setItem("ContinueWatching", "true");
            history.push({
              pathname: "/videoDetails",
              state: { movie: movie, show_id: apiRes.show_id },
            });
          };

          const onClickYes = () => {
            service.setCookie("showId", apiRes.show_id, 10);
            service.setCookie("videoId", movie.video_id, 10);
            history.push({
              pathname: "/SubscriptionList",
              state: {
                videoData: movie.video_id,
              },
            });
          };

          service.videoSubscription(movie.video_id).then((response) => {
            let videoSubDetails = response.data;
            let subFlag = true;
            service.userSubscription(uId).then((useResponse) => {
              if (useResponse.success == true) {
                var userSubDetails = useResponse.data;
                if (useResponse.forcibleLogout === true) {
                  alert(
                    "Sorry, You’ve reached the maximum Device limit. Please log in again!"
                  );
                  service.logoutAll(uId).then((res) => {
                    setTimeout(() => {
                      redirectToLogin();
                    }, 1000);
                  });
                } else if (useResponse.session_expired === true) {
                  ToastsStore.warning("Sorry! Session Has Expired");
                  redirectToLogin();
                } else {
                  // let subCount =
                  //   userSubDetails &&
                  //   userSubDetails.filter(
                  //     (e) =>
                  //       e.subscription_type_id == 4 ||
                  //       e.subscription_type_id == 3
                  //   );
                  // if (subCount && subCount.length > 0) {
                  //   service.setCookie("showId", apiRes.show_id, 10);
                  //   localStorage.setItem("ContinueWatching", "true");
                  //   history.push({
                  //     pathname: "/videoDetails",
                  //     state: { movie: movie, show_id: apiRes.show_id },
                  //   });
                  // } else {
                  videoSubDetails.map(function (subscription, index) {
                    if (useResponse.data.length == 0 && subFlag) {
                      subFlag = false;
                      freeVideoPopup(onClickYes, onClickNo);
                    } else {
                      let subscribedVideo = userSubDetails.filter(
                        (e) =>
                          e.sub_id == subscription.publisher_subscription_id
                      );
                      if (
                        subscribedVideo.length == 0 &&
                        index + 1 < videoSubDetails.length
                      ) {
                        return;
                      }
                      if (
                        subscribedVideo.length == 0 &&
                        subFlag &&
                        index + 1 == videoSubDetails.length
                      ) {
                        subFlag = false;
                        freeVideoPopup(onClickYes, onClickNo);
                      } else if (subFlag) {
                        subFlag = false;
                        service.setCookie("showId", apiRes.show_id, 10);
                        localStorage.setItem("ContinueWatching", "true");
                        history.push({
                          pathname: "/videoDetails",
                          state: { movie: movie, show_id: apiRes.show_id },
                        });
                      }
                    }
                  });
                  // }
                }
              }
            });
          });
        } else if (
          apiRes &&
          apiRes.videos &&
          apiRes.videos[0] &&
          apiRes.videos[0].free_video == true && //free video without any subscriptions
          apiRes.videos[0].subscriptions &&
          apiRes.videos[0].subscriptions.length == 0
        ) {
          let movie = apiRes.videos[0];
          service.setCookie("showId", apiRes.show_id, 10);
          localStorage.setItem("ContinueWatching", "true");
          history.push({
            pathname: "/videoDetails",
            state: { movie: movie, show_id: apiRes.show_id },
          });
        }
      }
    }
  };

  const onEpisodePlay = (video) => {
    let user_id = service.getCookie("userId");
    if (
      user_id == undefined ||
      user_id == null ||
      user_id == service.getCookie("guestUserId")
    ) {
      history.push({
        pathname: "/signin",
      });
    } else {
      if (
        video &&
        video.free_video != true &&
        video.screening_enabled != true
      ) {
        service.videoSubscription(video.video_id).then((response) => {
          let videoDetails = response.data;
          let subFlag = true;
          let uId = service.getCookie("guestUserId");
          let user_id = service.getCookie("userId");
          if (user_id) {
            uId = user_id;
          }

          // ********
          service.userSubscription(uId).then((useResponse) => {
            var userData = useResponse.data;

            if (useResponse.success == true) {
              if (useResponse.forcibleLogout === true) {
                alert(
                  "Sorry, You’ve reached the maximum Device limit. Please log in again!"
                );
                service.logoutAll(uId).then((res) => {
                  setTimeout(() => {
                    redirectToLogin();
                  }, 1000);
                });
              } else if (useResponse.session_expired === true) {
                ToastsStore.warning("Sorry! Session Has Expired");
                redirectToLogin();
              } else {
                videoDetails.map(function (subscription, index) {
                  if (useResponse.data.length == 0 && subFlag) {
                    subFlag = false;
                    service.setCookie("showId", apiRes.show_id, 10);
                    service.setCookie("videoId", video.video_id, 10);
                    history.push({
                      pathname: "/SubscriptionList",
                      state: {
                        videoData: video.video_id,
                      },
                    });
                  } else {
                    let subscribedVideo = userData.filter(
                      (e) => e.sub_id == subscription.publisher_subscription_id
                    );
                    if (
                      subscribedVideo.length == 0 &&
                      index + 1 < videoDetails.length
                    ) {
                      return;
                    }
                    if (
                      subscribedVideo.length == 0 &&
                      subFlag &&
                      index + 1 == videoDetails.length
                    ) {
                      subFlag = false;
                      service.setCookie("showId", apiRes.show_id, 10);
                      service.setCookie("videoId", video.video_id, 10);
                      history.push({
                        pathname: "/SubscriptionList",
                        state: {
                          videoData: video.video_id,
                        },
                      });
                    } else if (subFlag) {
                      subFlag = false;
                      service.setCookie("showId", apiRes.show_id, 10);
                      localStorage.setItem("ContinueWatching", "true");
                      history.push({
                        pathname: "/videoDetails",
                        state: { movie: video, show_id: apiRes.show_id },
                      });
                    }
                  }
                });
              }
            }
          });
        });
      } else {
        if (video && video.screening_enabled == true) {
          service.setCookie("showId", apiRes.show_id, 10);
          localStorage.setItem("ContinueWatching", "true");
          history.push({
            pathname: "/videoDetails",
            state: { movie: video, show_id: apiRes.show_id },
          });
        } else if (
          video &&
          video.free_video == true &&
          video.subscriptions && //free video  but have subscriptions
          video.subscriptions.length > 0
        ) {
          let uId = service.getCookie("guestUserId");
          let user_id = service.getCookie("userId");
          if (user_id) {
            uId = user_id;
          }
          const onClickNo = () => {
            service.setCookie("showId", apiRes.show_id, 10);
            localStorage.setItem("ContinueWatching", "true");
            history.push({
              pathname: "/videoDetails",
              state: { movie: video, show_id: apiRes.show_id },
            });
          };

          const onClickYes = () => {
            service.setCookie("showId", apiRes.show_id, 10);
            service.setCookie("videoId", video.video_id, 10);
            history.push({
              pathname: "/SubscriptionList",
              state: {
                videoData: video.video_id,
              },
            });
          };

          service.videoSubscription(video.video_id).then((response) => {
            let videoSubDetails = response.data;
            let subFlag = true;
            service.userSubscription(uId).then((useResponse) => {
              if (useResponse.success == true) {
                var userSubDetails = useResponse.data;
                if (useResponse.forcibleLogout === true) {
                  alert(
                    "Sorry, You’ve reached the maximum Device limit. Please log in again!"
                  );
                  service.logoutAll(uId).then((res) => {
                    setTimeout(() => {
                      redirectToLogin();
                    }, 1000);
                  });
                } else if (useResponse.session_expired === true) {
                  ToastsStore.warning("Sorry! Session Has Expired");
                  redirectToLogin();
                } else {
                  // let subCount =
                  //   userSubDetails &&
                  //   userSubDetails.filter(
                  //     (e) =>
                  //       e.subscription_type_id == 4 ||
                  //       e.subscription_type_id == 3
                  //   );
                  // if (subCount && subCount.length > 0) {
                  //   service.setCookie("showId", apiRes.show_id, 10);
                  //   localStorage.setItem("ContinueWatching", "true");
                  //   history.push({
                  //     pathname: "/videoDetails",
                  //     state: { movie: video, show_id: apiRes.show_id },
                  //   });
                  // } else {
                  videoSubDetails.map(function (subscription, index) {
                    if (useResponse.data.length == 0 && subFlag) {
                      subFlag = false;
                      freeVideoPopup(onClickYes, onClickNo);
                    } else {
                      let subscribedVideo = userSubDetails.filter(
                        (e) =>
                          e.sub_id == subscription.publisher_subscription_id
                      );
                      if (
                        subscribedVideo.length == 0 &&
                        index + 1 < videoSubDetails.length
                      ) {
                        return;
                      }
                      if (
                        subscribedVideo.length == 0 &&
                        subFlag &&
                        index + 1 == videoSubDetails.length
                      ) {
                        subFlag = false;
                        freeVideoPopup(onClickYes, onClickNo);
                      } else if (subFlag) {
                        subFlag = false;
                        service.setCookie("showId", apiRes.show_id, 10);
                        localStorage.setItem("ContinueWatching", "true");
                        history.push({
                          pathname: "/videoDetails",
                          state: { movie: video, show_id: apiRes.show_id },
                        });
                      }
                    }
                  });
                  // }
                }
              }
            });
          });
        } else if (
          //free video without any subscriptions
          video &&
          video.free_video == true &&
          video.subscriptions &&
          video.subscriptions.length == 0
        ) {
          service.setCookie("showId", apiRes.show_id, 10);
          localStorage.setItem("ContinueWatching", "true");
          history.push({
            pathname: "/videoDetails",
            state: { movie: video, show_id: apiRes.show_id },
          });
        }
      }
    }
  };

  const redirectToLogin = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    localStorage.removeItem("deviceAnalytics");
    service.setCookie("isLoggedIn", false, 30);
    localStorage.removeItem("isLoggedIn");
    service.eraseCookie("showId");
    service.eraseCookie("isScreeningUser");
    // dispatch({ type: "LOGOUT" });
    // setMouseHover(false);
    service.eraseCookie("userName");
    service.eraseCookie("userId");
    service.eraseCookie("userEmail");
    service.eraseCookie("subscriptionId");
    sessionStorage.removeItem("applaunch");
    setTimeout(() => {
      window.location.href = "/signin";
    }, 1500);
  };

  return (
    <div className="sidePadExs" style={{ backgroundColor: "#eaeaea" }}>
      {apiRes && (
        <BannerHead
          apiData={apiRes}
          clickHandler={onPlayVideo}
          vanityURL={showName}
        />
      )}

      {apiRes && apiRes.single_video == 0 && (
        <ShowEpisode apiData={apiRes} onEpisodePlay={onEpisodePlay} />
      )}

      {apiRes && <ShowCategory apiData={apiRes} />}

      {apiRes && apiRes.key_art_work && apiRes.key_art_work.length > 0 && (
        <KeyArticles keyArtwork={apiRes.key_art_work} />
      )}

      {apiRes && apiRes.awards && apiRes.awards.length > 0 && (
        <AwardsFestivals apiData={apiRes} />
      )}

      {apiRes && <Cast apiData={apiRes} type="Cast" />}
      {apiRes && <Cast apiData={apiRes} type="Crew" />}

      <ToastsContainer store={ToastsStore} />
    </div>
  );
}
export default ShowDetails;
