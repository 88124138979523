import React, { useState, useEffect } from "react";
import "./ThumbnailGrid.css";
import Thumbnail from "../ThumbnailGrid/Thumbnail";
import Notification from "../../common/Notification";
import { service } from "../../Network/service";
import { useSelector } from "react-redux";

import TrailerPopUp from "../TrailerPopUp/TrailerPopUp";

const ThumbnailGrid = (params) => {
  const signInBlock = useSelector((state) => state.signInBlock);
  const [shows, setShows] = useState();

  const [showTrailer, setShowTrailer] = useState(false);
  const [apiRes, setApiRes] = useState("");

  const onClickTralier = (showId) => {
    setShowTrailer(true);
    service.getShowDetails(showId).then((response) => {
      if (response.success == true && response.data) {
        setApiRes(response.data);
      }
    });
  };

  useEffect(() => {
    setShows(params.shows);
  }, [params]);
  return (
    <div className="thumbnail__grid__container">
      {signInBlock === true ? <Notification /> : null}
      {showTrailer && apiRes && (
        <TrailerPopUp
          show={showTrailer}
          apiData={apiRes}
          onClose={() => {
            setShowTrailer(false);
            setApiRes("");
          }}
        />
      )}
      {shows &&
        shows.map((item, index) => {
          return (
            <Thumbnail
              key={index}
              show={item}
              clickHandler={params.clickHandler}
              onClickTralier={onClickTralier}
            />
          );
        })}
    </div>
  );
};

export default ThumbnailGrid;
