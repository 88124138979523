import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./Thumbnail.css";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useDispatch } from "react-redux";
import { service } from "../../Network/service";
import Tooltip from "@material-ui/core/Tooltip";
import TooltipMsg from "../Slider/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { ToastsContainer, ToastsStore } from "react-toasts";
import freeIcon from "../../images/free.png";

import VisibilityIcon from "@material-ui/icons/Visibility";

let url = "https://gizmeon.s.llnwi.net/vod/thumbnails/show_logo/";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#fff",
    color: "#000",
    fontSize: "12px",
    boxShadow: "1px 1px 9px",
  },
  customArrow: {
    color: "#fff",
  },
}));

const Thumbnail = ({ show, clickHandler, onClickTralier }) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const classes = useStyles();
  const [watchListmsg, setWatchListmsg] = useState(false);

  const addtoMylistFunction = (show) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      service.addToMyPlayList(show.show_id, 1).then((response) => {
        clickHandler();
        setWatchListmsg("Added to watchlist");
        setTimeout(function () {
          setWatchListmsg(false);
        }, 1000);
      });
    } else {
      dispatch({ type: "SIGN_IN_BLOCK" });
    }
  };
  const removeFromMylistFunction = (show) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      service.addToMyPlayList(show.show_id, 0).then((response) => {
        clickHandler();
        setWatchListmsg("Removed from watchlist");
        setTimeout(function () {
          setWatchListmsg(false);
        }, 2000);
      });
    } else {
      dispatch({ type: "SIGN_IN_BLOCK" });
    }
  };

  const redirectToLogin = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    localStorage.removeItem("deviceAnalytics");
    service.setCookie("isLoggedIn", false, 30);
    localStorage.removeItem("isLoggedIn");
    service.eraseCookie("showId");
    service.eraseCookie("isScreeningUser");
    service.eraseCookie("userName");
    service.eraseCookie("userId");
    service.eraseCookie("userEmail");
    service.eraseCookie("subscriptionId");
    sessionStorage.removeItem("applaunch");
    setTimeout(() => {
      window.location.href = "/signin";
    }, 1500);
  };

  const onClickPlaybutton = (ShowId, videoId) => {
    if (ShowId && videoId) {
      service.getShowDetails(ShowId).then((response) => {
        if (response.success == true && response.data && response.data.videos) {
          let videoList = response.data.videos.filter(
            (e) => e.video_id == videoId
          );
          if (
            videoList &&
            videoList[0] &&
            videoList[0].screening_enabled != true &&
            videoList[0].subscriptions &&
            videoList[0].subscriptions.length > 0
          ) {
            let movie = videoList[0];
            service.videoSubscription(movie.video_id).then((response) => {
              let videoDetails = response.data;
              let subFlag = true;
              let uId = service.getCookie("guestUserId");
              let user_id = service.getCookie("userId");
              if (user_id) {
                uId = user_id;
              }
              service.userSubscription(uId).then((useResponse) => {
                if (useResponse.success == true) {
                  var userData = useResponse.data;
                  if (useResponse.forcibleLogout === true) {
                    alert(
                      "Sorry, You’ve reached the maximum Device limit. Please log in again!"
                    );
                    service.logoutAll(uId).then((res) => {
                      setTimeout(() => {
                        redirectToLogin();
                      }, 1000);
                    });
                  } else if (useResponse.session_expired === true) {
                    ToastsStore.warning("Sorry! Session Has Expired");
                    redirectToLogin();
                  } else {
                    videoDetails.map(function (subscription, index) {
                      if (useResponse.data.length == 0 && subFlag) {
                        subFlag = false;
                        service.setCookie("showId", ShowId, 10);
                        service.setCookie("videoId", movie.video_id, 10);
                        history.push({
                          pathname: "/SubscriptionList",
                          state: {
                            videoData: movie.video_id,
                          },
                        });
                      } else {
                        let subscribedVideo = userData.filter(
                          (e) =>
                            e.sub_id == subscription.publisher_subscription_id
                        );
                        if (
                          subscribedVideo.length == 0 &&
                          index + 1 < videoDetails.length
                        ) {
                          return;
                        }
                        if (
                          subscribedVideo.length == 0 &&
                          subFlag &&
                          index + 1 == videoDetails.length
                        ) {
                          subFlag = false;
                          service.setCookie("showId", ShowId, 10);
                          service.setCookie("videoId", movie.video_id, 10);
                          history.push({
                            pathname: "/SubscriptionList",
                            state: {
                              videoData: movie.video_id,
                            },
                          });
                        } else if (subFlag) {
                          subFlag = false;
                          service.setCookie("showId", ShowId, 10);
                          localStorage.setItem("ContinueWatching", "true");
                          history.push({
                            pathname: "/videoDetails",
                            state: { movie: movie, show_id: ShowId },
                          });
                        }
                      }
                    });
                  }
                }
              });
            });
          } else {
            if (videoList && videoList[0]) {
              let movie = videoList[0];
              service.setCookie("showId", ShowId, 10);
              localStorage.setItem("ContinueWatching", "true");
              history.push({
                pathname: "/videoDetails",
                state: { movie: movie, show_id: ShowId },
              });
            }
          }
        } else {
          history.push("/404");
        }
      });
    }
  };

  return (
    <div>
      <ToastsContainer store={ToastsStore} />
      <div className="thumbnail__wrapper">
        <div className="thumbnail__container">
          <img src={url + show.logo_thumb} className="thumbnail__image" />
        </div>
        <div className="thumbnail__overlay__container">
          <div className="css-1dtqbq"></div>
          <div className="thumbnail__overlay__text__container">
            <div className="divfree">
              {show && show.is_free_video && (
                <span>
                  <img src={freeIcon} />
                </span>
              )}
              <h3 className="thumbnail__title">{show && show.show_name}</h3>
            </div>
            <div className="thumbnail__desc">
              <span className="thumbnail__desc__span">
                {show && show.director ? show.director : ""}
                {show && show.director && show.year ? " . " : ""}
                {show && show.year ? show.year : ""}
                {show && show.duration_text && show.year ? " . " : ""}
                {/* {show && params.director && show.duration_text && !show.year
              ? " . "
              : ""} */}
                {show && show.duration_text ? show.duration_text : ""}
              </span>
            </div>
          </div>
        </div>
        {show.teaser && (
          <div className="thumbnail__trailer__eye">
            <Tooltip
              title="Play trailer"
              arrow
              classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow,
              }}
            >
              <VisibilityIcon
                style={{ fontSize: "23px", color: "white", cursor: "pointer" }}
                onClick={() => {
                  onClickTralier(show.show_id);
                }}
              />
            </Tooltip>
          </div>
        )}
        <div className="thumbnail__playButton">
          <svg
            viewBox="0 0 44 44"
            className="playSVG playSVGThumb"
            style={{ width: "40px" }}
            onClick={() => {
              service.setCookie("showTitle", show.vanity_url, 10);
              history.push({
                pathname: `/movies/${show.vanity_url?.trim()}`,
              });
              // onClickPlaybutton(show.show_id, show.video_id);
              // if (show.single_video == 0) {
              //   service.setCookie("showTitle", show.vanity_url, 10);
              //   onClickPlaybutton(show.show_id, show.video_id);
              // } else {
              //   history.push({
              //     pathname: `/movies/${show.vanity_url?.trim()}`,
              //     // pathname: "/home/movies",
              //     // search: encodeURI(`show_id=${show.show_id}`),
              //   });
              // }
            }}
          >
            <g transform="translate(1 1)" fillRule="evenodd" className="playG">
              <circle cx="21" cy="21" r="21" className="playCircle"></circle>
              <path fill="#FFFFFF" d="M30 21.5L15 29V14z"></path>
            </g>
          </svg>
        </div>
        <div className="add__button">
          {show.watchlist_flag === 1 ? (
            <div>
              <Tooltip
                title="Remove from watchlist"
                arrow
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
              >
                <AddCircleOutlineIcon
                  style={{
                    fontSize: "28px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    removeFromMylistFunction(show);
                  }}
                />
              </Tooltip>
              {watchListmsg == "Removed from watchlist" ? (
                <div style={{ position: "relative" }}>
                  <TooltipMsg title={watchListmsg} />
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              <Tooltip
                title="Add to watchlist"
                arrow
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
              >
                <AddCircleIcon
                  style={{
                    fontSize: "28px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    addtoMylistFunction(show);
                  }}
                />
              </Tooltip>
              {watchListmsg == "Added to watchlist" ? (
                <div style={{ position: "relative" }}>
                  <TooltipMsg title={watchListmsg} />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Thumbnail;
