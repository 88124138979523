import React, { useState, useEffect } from "react";
import "./ShowDetailStyle.css";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import StarsIcon from "@material-ui/icons/Stars";
import { service } from "./service";
import TrailerPopUp from "../TrailerPopUp/TrailerPopUp";
import TooltipMsg from "../Slider/Tooltip";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import CloseIcon from "@material-ui/icons/Close";
import RatingStar from "@material-ui/lab/Rating";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useHistory } from "react-router-dom";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

import Rating from "./Rating";

let url = "https://gizmeon.s.llnwi.net/vod/thumbnails/show_logo/";
function BannerHead({ apiData, clickHandler, vanityURL }) {
  const [watchlistFlag, setWatchFlag] = useState(apiData.watchlist_flag);
  const [likedFlag, setLikedFlag] = useState(apiData.liked_flag);
  const [showTrailer, setShowTrailer] = useState(false);
  const [watchListmsg, setWatchListmsg] = useState(false);
  const [favListmsg, setFavListmsg] = useState(false);
  const [share, setShare] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [userRated, setUserRated] = useState(apiData.user_rated);
  const [userRating, setUserRating] = useState(apiData.user_rating);
  const history = useHistory();
  const [socialMediaLink, setSocialMediaLink] = useState(
    apiData.social_media_links
  );

  let isLoggedIn = localStorage.getItem("isLoggedIn");


  const socialMediaFunction = () => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      setShare(!share);
    }
  };

  const addtoMylistFunction = (show) => {
    service.addToMyPlayList(show.show_id, 1).then((response) => {
      if (response.success == true) {
        setWatchFlag(1);
        setWatchListmsg("Added to watchlist");
        setTimeout(function () {
          setWatchListmsg(false);
        }, 1500);
      }
    });
  };
  const removeFromMylistFunction = (show) => {
    service.addToMyPlayList(show.show_id, 0).then((response) => {
      if (response.success == true) {
        setWatchFlag(0);
        setWatchListmsg("Removed from watchlist");
        setTimeout(function () {
          setWatchListmsg(false);
        }, 1500);
      }
    });
  };

  const addToFavorite = (show) => {
    service.FavouriteShow(show.show_id, 1).then((response) => {
      if (response.success == true) {
        setLikedFlag(1);
        setFavListmsg("Added to favorites");
        setTimeout(function () {
          setFavListmsg(false);
        }, 1500);
      }
    });
  };

  const RemovefromFavorite = (show) => {
    service.FavouriteShow(show.show_id, 0).then((response) => {
      if (response.success == true) {
        setLikedFlag(0);
        setFavListmsg("Removed from favorites");
        setTimeout(function () {
          setFavListmsg(false);
        }, 1500);
      }
    });
  };

  const userRatedSuccess = (value) => {
    setUserRated(true);
    setUserRating(value);
  };

  return (
    <div className="dpBanner">
      {showTrailer && (
        <TrailerPopUp
          show={showTrailer}
          apiData={apiData}
          onClose={() => {
            setShowTrailer(false);
          }}
        />
      )}
      {showRating && (
        <Rating
          onClose={() => {
            setShowRating(false);
          }}
          apiData={apiData}
          show={showRating}
          userRatedSuccess={userRatedSuccess}
        />
      )}
      <div className="detailsBannerSection">
        <div className="detailsBannerImgWrapper">
          <img
            src={url + apiData.logo_thumb}
            alt=""
            className="bannerImgshow"
          />
          <img
            src={url + apiData.logo_thumb}
            alt=""
            className="bannerImgMobileshow"
          />
        </div>
        <div className="detailsBannerWrapper">
          <div className="bsTotalRating">
            <div
              className="backbutton"
              title="Return to home page"
              // style={{
              //   cursor: "pointer",
              // }}
            >
              <KeyboardBackspaceIcon
                style={{
                  color: "white",
                  fontSize: "30px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.push({
                    pathname: "/home",
                  });
                }}
              />
            </div>
            <div className="bsTotalRatingColor">
              {apiData.imdb_rating ? (
                <div className="bsTotalRatingBold">
                  <div width="20px" className="bsTotalRatingIcon">
                    <svg
                      viewBox="0 0 22 20"
                      fill="#FFFFFF"
                      className="bcPlayButtonSVG"
                    >
                      <path d="M21.15 7.6a.64.64 0 0 0-.6-.45l-7.05-.14L11.2.43a.63.63 0 0 0-1.2 0L7.67 7l-7.05.14a.63.63 0 0 0-.59.44c-.08.26 0 .54.22.7l5.62 4.22-2.04 6.67a.64.64 0 0 0 .97.71l5.79-3.99 5.8 3.99a.64.64 0 0 0 .73-.01c.22-.16.3-.44.23-.7l-2.04-6.67 5.62-4.21c.21-.17.3-.45.22-.7"></path>
                    </svg>
                  </div>
                  <div className="bsRatingAcheived">{apiData.imdb_rating}/</div>
                  <div className="bsRatingTotal">10</div>
                </div>
              ) : null}
              {apiData.number_of_rating ? (
                <div className="bsRatingCount">
                  {apiData.number_of_rating} Ratings
                </div>
              ) : null}
            </div>
          </div>
          {apiData.single_video == 1 ? (
            <div className="bsRatingPlayWrapper">
              <div className="blackOverlayshow">
                <a style={{ cursor: "pointer" }}>
                  <svg
                    style={{ width: "75px" }}
                    viewBox="0 0 44 44"
                    className="playSVGshow"
                    onClick={clickHandler}
                  >
                    <g
                      transform="translate(1 1)"
                      fillRule="evenodd"
                      className="playPathshow"
                    >
                      <circle
                        cx="21"
                        cy="21"
                        r="21"
                        className="playCircleshow"
                      ></circle>
                      <path fill="#FFFFFF" d="M30 21.5L15 29V14z"></path>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          ) : (
            <div className="bsRatingPlayWrapper"></div>
          )}
          <div className="bsRatingContentWrapper">
            <section className="bsRatingContentSection">
              <div className="bsRatingContent">
                <div className="detailsBannerWrapper">
                  <div id="FilmDetailsAboutFilm" className="bsContentThree">
                    {apiData.teaser && (
                      <div
                        className="dpBannerTrailerBtnWrapper"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowTrailer(true);
                        }}
                      >
                        <div>
                          <a className="dpBannerTrailerAnchorBtn">
                            Trailer
                            <div className="dpBannerTrailerPlay">
                              <svg
                                viewBox="0 0 14 15"
                                className="bcPlayButtonSVG"
                              >
                                <path
                                  d="M14.029 7.087l-14 7v-14z"
                                  fill="#FFFFFF"
                                  fillRule="evenodd"
                                ></path>
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                    )}
                    <div className="bsContentHeading">
                      <h1 itemProp="name" className="bsContentH2">
                        {apiData.show_name}
                      </h1>
                    </div>
                    <div className="bsContentAllThree">
                      <div className="bsContentFirstSection">
                        <div className="bsContentDirected">
                          <span className="bsContentDirectedSpan">
                            Directed by &nbsp;
                          </span>
                          <span className="bsDirectedFontSize">
                            <span className=" css-1efi8gv e3wn9bk2">
                              <a
                                color="#FFFFFF"
                                className="css-1bhvh5d esf27f30"
                              >
                                <span itemProp="name">{apiData.director}</span>
                              </a>
                            </span>
                          </span>
                        </div>
                        <div className="bsContentCountry">
                          <span itemProp="dateCreated">{apiData.year}</span>
                        </div>
                        {apiData.single_video == 1 &&
                        apiData.videos &&
                        apiData.videos.length > 0 ? (
                          <div className="bsSubtypeList">
                            {apiData.videos[0].screening_enabled != true &&
                            apiData.videos[0].subscriptions.length > 0
                              ? apiData.videos[0].subscriptions.map(
                                  (item, index) => {
                                    return (
                                      <div key={index}>
                                        {item.subscription_type == 6 && (
                                          <button
                                            className="subscription_button"
                                            style={{ cursor: "pointer" }}
                                            onClick={clickHandler}
                                          >
                                            Rent
                                          </button>
                                        )}
                                        {item.subscription_type == 1 && (
                                          <button
                                            className="subscription_button"
                                            style={{ cursor: "pointer" }}
                                            onClick={clickHandler}
                                          >
                                            Buy
                                          </button>
                                        )}
                                        {item.subscription_type == 3 && (
                                          <button
                                            className="subscription_button"
                                            style={{ cursor: "pointer" }}
                                            onClick={clickHandler}
                                          >
                                            Subscribe
                                          </button>
                                        )}
                                      </div>
                                    );
                                  }
                                )
                              : null}
                            {apiData.videos[0].screening_enabled == true ? (
                              <button
                                className="subscription_button"
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "green",
                                }}
                                onClick={clickHandler}
                              >
                                Screening room video
                              </button>
                            ) : null}
                            {apiData.videos[0].screening_enabled != true &&
                            apiData.videos[0].free_video == true ? (
                              <button
                                className="subscription_button"
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "green",
                                }}
                                onClick={clickHandler}
                              >
                                Free
                              </button>
                            ) : null}
                          </div>
                        ) : null}
                        <ul className="bsContentTime">
                          <ul className="bsInfoBar">
                            <div className="bsInfoBarWrapper">
                              <div className="bsInfoOpacity">
                                <div
                                  style={{ width: "18px" }}
                                  className="peopleSayingCommentBox"
                                >
                                  <svg
                                    viewBox="0 0 17 16"
                                    className="bgImageSectionBtnSVGshow"
                                  >
                                    <path
                                      d="M8.2 0a8.1 8.1 0 018.22 8c0 4.42-3.68 8-8.21 8A8.1 8.1 0 010 8c0-4.42 3.67-8 8.2-8zm.27 3.52a.74.74 0 10-1.47 0v4.92c0 .2.08.38.21.52l3.52 3.51a.74.74 0 001.04-1.04l-3.3-3.3V3.52z"
                                      fill="#FFFFFF"
                                      fillRule="evenodd"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div className="bsInfoOpacity">
                              <time dateTime="PT55M" itemProp="duration">
                                {apiData.duration_text}
                              </time>
                            </div>
                          </ul>
                          <ul className="bsInfoBar">
                            <div className="bsInfoBarWrapper">
                              <div className="bsInfoOpacity">
                                <div
                                  width="19px"
                                  className="peopleSayingCommentBox"
                                >
                                  <svg
                                    viewBox="0 0 20 16"
                                    className="bgImageSectionBtnSVGshow"
                                  >
                                    <g fill="#FFFFFF" fillRule="evenodd">
                                      <path d="M12.31 3.04a.58.58 0 000 .83 5.86 5.86 0 01.08 8.14.58.58 0 00.43.98c.16 0 .32-.06.43-.19a7.04 7.04 0 00-.1-9.74.59.59 0 00-.84-.02"></path>
                                      <path d="M16.77.34a.59.59 0 00-.82-.05.58.58 0 00-.05.82 10.35 10.35 0 01.08 13.66c-.21.24-.19.61.06.82a.58.58 0 00.83-.06 11.51 11.51 0 00-.1-15.2M9.93 15.83a.6.6 0 01-.32-.1l-4.62-4.65H.75a.58.58 0 01-.57-.58V4.67c0-.32.25-.58.57-.58h4.24L9.61.1c.18-.12.4-.13.59-.03.19.1.3.3.3.51v14.66a.58.58 0 01-.57.58"></path>
                                    </g>
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div className="bsInfoOpacity">
                              {apiData.audio_language_name}
                            </div>
                          </ul>
                          {apiData.subtitles && (
                            <ul className="bsInfoBar">
                              <div className="bsInfoBarWrapper">
                                <div className="bsInfoOpacity">
                                  <div
                                    width="16px"
                                    className="peopleSayingCommentBox"
                                  >
                                    <svg
                                      viewBox="0 0 16 16"
                                      className="bgImageSectionBtnSVGshow"
                                    >
                                      <path
                                        d="M.62 15.83a.47.47 0 01-.24-.07.48.48 0 01-.25-.42V.77C.13.5.35.28.62.28h14.53c.27 0 .49.22.49.49v11.61c0 .27-.22.49-.49.49H5.68L.86 15.76a.48.48 0 01-.24.07zm11.63-8.77a.49.49 0 100-.97H3.52a.49.49 0 000 .97h8.73zm0 2.91c.27 0 .48-.21.48-.48a.48.48 0 00-.48-.49H3.52a.49.49 0 000 .97h8.73z"
                                        fill="#FFFFFF"
                                        fillRule="evenodd"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="bsInfoOpacity"></div>
                            </ul>
                          )}
                          <ul className="bsInfoBar">
                            <div>
                              <div>
                                <div id="button-ContentRating">
                                  <div className="css-e0dnmk e1ffq2le2">
                                    <div className="bsInfoBarGeneral">
                                      {apiData.rating}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ul>
                        </ul>
                        {isLoggedIn == true || isLoggedIn == "true" ? (
                          <div className="personalisation">
                            <div>
                              <div className="personalisationSpacing">
                                {!userRated && (
                                  <div className="personalisationSeperate">
                                    <button
                                      type="button"
                                      title="Rate and review"
                                      className="personalisationRatingBtn"
                                      onClick={() => {
                                        setShowRating(true);
                                      }}
                                    >
                                      <div
                                        width="28px"
                                        className="personalisationBtnBox"
                                      >
                                        <StarsIcon
                                          style={{
                                            fontSize: "32px",
                                            color: "white",
                                            margin: "-2px",
                                          }}
                                        />
                                      </div>
                                    </button>
                                  </div>
                                )}

                                {/*  */}
                                {socialMediaLink.length > 0 &&
                                  socialMediaLink.map((item, index) => {
                                    if (item.name == "Facebook") {
                                      return (
                                        <div className="personalisationSeperate">
                                          <div title="Facebook">
                                            <div>
                                              <div className="personalisationCursor">
                                                <div
                                                  width="28px"
                                                  className="personalisationBtnBox"
                                                  onClick={() => {
                                                    window.open(
                                                      item.link,
                                                      "_blank"
                                                    );
                                                  }}
                                                >
                                                  <FacebookIcon
                                                    style={{
                                                      fontSize: "32px",
                                                      color: "white",
                                                      margin: "-2px",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    } else if (item.name == "Instagram") {
                                      return (
                                        <div className="personalisationSeperate">
                                          <div title="Instagram">
                                            <div>
                                              <div className="personalisationCursor">
                                                <div
                                                  width="28px"
                                                  className="personalisationBtnBox"
                                                  onClick={() => {
                                                    window.open(
                                                      item.link,
                                                      "_blank"
                                                    );
                                                  }}
                                                >
                                                  <InstagramIcon
                                                    style={{
                                                      fontSize: "32px",
                                                      color: "white",
                                                      margin: "-2px",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return null;
                                    }
                                  })}

                                {/*  */}

                                <div className="personalisationSeperate">
                                  <div title="Favorite">
                                    <div>
                                      <div className="personalisationCursor">
                                        <div
                                          width="28px"
                                          className="personalisationBtnBox"
                                        >
                                          {likedFlag == 1 ? (
                                            <div>
                                              <svg
                                                viewBox="0 0 28 28"
                                                fill="#FFFFFF"
                                                className="css-1b4920d e1k0l6qr0"
                                                onClick={() => {
                                                  RemovefromFavorite(apiData);
                                                }}
                                              >
                                                <circle
                                                  fill-opacity="0.2"
                                                  cx="14"
                                                  cy="14"
                                                  r="14"
                                                ></circle>
                                                <path
                                                  d="M17.64 7C15.34 7 14 8.9 14 8.9S12.67 7 10.36 7A4.27 4.27 0 0 0 6 11.16c0 4.04 4.95 7.9 7.2 9.58a1.36 1.36 0 0 0 1.6 0c2.25-1.68 7.2-5.54 7.2-9.57C22 8.86 20.05 7 17.64 7"
                                                  fill="#F51315"
                                                ></path>
                                              </svg>
                                              {favListmsg ===
                                              "Added to favorites" ? (
                                                <div
                                                  style={{
                                                    position: "relative",
                                                  }}
                                                >
                                                  <TooltipMsg
                                                    title={favListmsg}
                                                    position="top"
                                                    style={{
                                                      right: "-39px",
                                                      top: "-70px",
                                                      boxShadow: "none",
                                                    }}
                                                  />
                                                </div>
                                              ) : null}
                                            </div>
                                          ) : (
                                            <div>
                                              <svg
                                                viewBox="0 0 28 28"
                                                fill="#FFFFFF"
                                                className="bcPlayButtonSVG"
                                                onClick={() => {
                                                  addToFavorite(apiData);
                                                }}
                                              >
                                                <path d="M14 28a14 14 0 1 1 0-28 14 14 0 0 1 0 28zm3.64-21C15.34 7 14 8.9 14 8.9S12.67 7 10.36 7A4.27 4.27 0 0 0 6 11.16c0 4.04 4.95 7.9 7.2 9.58a1.36 1.36 0 0 0 1.6 0c2.25-1.68 7.2-5.54 7.2-9.57C22 8.86 20.05 7 17.64 7z"></path>
                                              </svg>
                                              {favListmsg ===
                                              "Removed from favorites" ? (
                                                <div
                                                  style={{
                                                    position: "relative",
                                                  }}
                                                >
                                                  <TooltipMsg
                                                    title={favListmsg}
                                                    position="top"
                                                    style={{
                                                      right: "-39px",
                                                      top: "-70px",
                                                      boxShadow: "none",
                                                    }}
                                                  />
                                                </div>
                                              ) : null}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="personalisationSeperate">
                                  <div>
                                    <div>
                                      {watchlistFlag == 1 ? (
                                        <div
                                          title="Remove from Watchlist"
                                          className="personalisationCursor"
                                        >
                                          <div
                                            width="28px"
                                            className="personalisationBtnBox"
                                          >
                                            <div>
                                              <AddCircleOutlineIcon
                                                style={{
                                                  fontSize: "32px",
                                                  color: "white",
                                                  margin: "-2px",
                                                }}
                                                onClick={() => {
                                                  removeFromMylistFunction(
                                                    apiData
                                                  );
                                                }}
                                              />
                                              {watchListmsg ===
                                              "Added to watchlist" ? (
                                                <div
                                                  style={{
                                                    position: "relative",
                                                  }}
                                                >
                                                  <TooltipMsg
                                                    title={watchListmsg}
                                                    position="top"
                                                    style={{
                                                      right: "-39px",
                                                      top: "-70px",
                                                      boxShadow: "none",
                                                    }}
                                                  />
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          title="Add to Watchlist"
                                          className="personalisationCursor"
                                        >
                                          <div
                                            width="28px"
                                            className="personalisationBtnBox"
                                          >
                                            <div>
                                              <AddCircleIcon
                                                style={{
                                                  fontSize: "32px",
                                                  color: "white",
                                                  margin: "-2px",
                                                }}
                                                onClick={() => {
                                                  addtoMylistFunction(apiData);
                                                }}
                                              />
                                              {watchListmsg ===
                                              "Removed from watchlist" ? (
                                                <div
                                                  style={{
                                                    position: "relative",
                                                  }}
                                                >
                                                  <TooltipMsg
                                                    title={watchListmsg}
                                                    position="top"
                                                    style={{
                                                      right: "-39px",
                                                      top: "-70px",
                                                      boxShadow: "none",
                                                    }}
                                                  />
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {userRating && userRating != 0 ? (
                                  <div className="personalisationSeperate">
                                    <div className="readonly-rating-star">
                                      <RatingStar
                                        value={userRating}
                                        name="rating"
                                        readOnly="true"
                                        className="ratingStar"
                                      />
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {/*  */}
                        {apiData.external_links &&
                          apiData.external_links.length > 0 && (
                            <div
                              className="personalisation externalLinks"
                              // style={{ borderTop: "none" }}
                            >
                              <div>
                                <div className="personalisationSpacing externalLinkSpacing">
                                  {apiData.external_links &&
                                    apiData.external_links.length > 0 &&
                                    apiData.external_links.map(
                                      (item, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="personalisationSeperate"
                                            style={{ paddingRight: "5px" }}
                                          >
                                            <button
                                              type="button"
                                              title={item.name}
                                              className="personalisationRatingBtn"
                                            >
                                              <div
                                                width="28px"
                                                className="personalisationBtnBox"
                                              >
                                                <a
                                                  href={item.link_url}
                                                  target="_blank"
                                                >
                                                  <img
                                                    src={item.icon}
                                                    style={{
                                                      filter: "grayscale(1)",
                                                    }}
                                                  />
                                                </a>
                                              </div>
                                            </button>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                        {/*  */}
                      </div>
                      <div className="bsContentTwoSection">
                        {apiData.synopsis && (
                          <section className="bsContentTwoWrap">
                            <h2 className="bsContentTwoH2">Synopsis</h2>
                            <div
                              itemProp="description"
                              className="css-moives eyplj685"
                            >
                              <p>
                                {apiData.synopsis.length > 380
                                  ? apiData.synopsis.substring(0, 380) + "..."
                                  : apiData.synopsis}
                              </p>
                            </div>
                          </section>
                        )}
                        {apiData.our_take && (
                          <section className="bsContentTwoWrap">
                            <h2 className="bsContentTwoH2">
                              Programmer’s notes
                            </h2>
                            <div
                              itemProp="description"
                              className="css-moives eyplj685"
                            >
                              <p>
                                {apiData.our_take.length > 380
                                  ? apiData.our_take.substring(0, 380) + "..."
                                  : apiData.our_take}
                              </p>
                            </div>
                          </section>
                        )}
                      </div>
                    </div>
                    <div className="bsTwoIcons">
                      <div className="bsIconDown">
                        <div className="css-1uk2of5 eyplj681">
                          <div
                            style={{ transform: "rotate(270deg)" }}
                            className="bsCionDownWrapper"
                          >
                            <svg
                              viewBox="0 0 10 25"
                              fill="#FFFFFF"
                              className="bgImageSectionBtnSVGshow"
                            >
                              <path d="M.22 13c-.3-.45-.29-1.04.01-1.5L7.7.27a.6.6 0 0 1 .86-.16l1.18.82c.28.2.36.58.17.86L2.93 12.28 9.9 23.21c.18.29.1.67-.18.86l-1.2.8a.6.6 0 0 1-.85-.18L.22 13z"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                      {localStorage.getItem("isLoggedIn") == "true" ? (
                        <div className="bsShareWrapper">
                          <div
                            fontWeight="400"
                            className="css-1w6hklf e1qw5qmt2"
                          >
                            Share
                          </div>
                          <div className="css-0 e1qw5qmt1">
                            <div
                              width="23px"
                              className="bsShareIcon"
                              style={{ marginBottom: "5px" }}
                              onClick={() => {
                                socialMediaFunction();
                              }}
                            >
                              {share === true ? (
                                <CloseIcon />
                              ) : (
                                <svg
                                  viewBox="0 0 24 14"
                                  className="bgImageSectionBtnSVGshow"
                                >
                                  <path
                                    d="M10.23 0l13.03 7-13.03 7V9.5H6.65c-3.07 0-5.29 1-6.65 3C0 12.5.51 4 10.23 4V0z"
                                    fill="#FFFFFF"
                                    fillRule="evenodd"
                                  ></path>
                                </svg>
                              )}
                            </div>
                          </div>
                          {share === true ? (
                            <div>
                              <div
                                className="_1TcfH _1Dgjh"
                                style={{ left: "7px", transition: "1s" }}
                              >
                                <FacebookShareButton
                                  url={
                                    "https://justwatchme.tv/movies/" +
                                    vanityURL?.trim()
                                  }
                                  quote={
                                    apiData.show_name +
                                    " || " +
                                    apiData.synopsis
                                  }
                                  className="share"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span className="ATag _1H0lX _135ID _3Dyhl">
                                    <svg
                                      className="svgIcon facebookIcon"
                                      preserveAspectRatio="xMidYMid meet"
                                      viewBox="0 0 20 20"
                                      style={{ fill: "currentcolor" }}
                                    >
                                      <path
                                        fill="currentColor"
                                        fillRule="evenodd"
                                        d="M2 0C.938 0 0 1.063 0 1.97v16.093C0 19.03 1.063 20 2 20h9v-8H8V9h3V7c-.318-2.573 1.26-3.98 4-4 .668.02 1.617.103 2 0v3h-2c-.957-.16-1.2.436-1 1v2h3l-1 3h-2v8h3.938c1.03 0 2.062-.938 2.062-1.938V1.97C20 1.03 18.937 0 17.937 0H2z"
                                      ></path>
                                    </svg>
                                    <span className="_3SXQW">Facebook</span>
                                  </span>
                                </FacebookShareButton>

                                <TwitterShareButton
                                  url={
                                    "https://justwatchme.tv/movies/" +
                                    vanityURL?.trim()
                                  }
                                  title={apiData.show_name}
                                  description={apiData.synopsis}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span className="ATag _1H0lX _135ID _3Dyhl">
                                    <svg
                                      className="svgIcon"
                                      preserveAspectRatio="xMidYMid meet"
                                      viewBox="0 0 20 17"
                                      style={{ fill: "currentcolor" }}
                                    >
                                      <path
                                        d="M6 17c7.837 0 11.965-6.156 12-11-.035-.67-.035-.844 0-1 .756-.59 1.45-1.297 2-2-.75.218-1.543.433-2 1 .5-.978 1.14-1.77 1-3-.358.763-1.24 1.095-2 1C15.29.647 12.69.568 11 2c-1.03 1.084-1.48 2.555-1 4-3.45-.204-6.524-1.74-9-4C.303 3.584.86 5.945 3 7c-.99.11-1.63-.062-2 0-.2 1.6 1.178 3.255 3 4-.512-.202-1.146-.178-2 0 .777 1.35 2.318 2.478 4 3-1.38.635-3.175 1.246-5 1-.35.244-.675.223-1 0 1.877 1.37 4.06 2 6 2"
                                        fill="currentColor"
                                        fillRule="evenodd"
                                      ></path>
                                    </svg>
                                    <span className="_3SXQW">Twitter</span>
                                  </span>
                                </TwitterShareButton>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="TrailerShareWrapper">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowTrailer(true);
                }}
              >
                <a className="trailerMobile">
                  Trailer
                  <div className="trailerMobilePlay">
                    <svg viewBox="0 0 14 15" className="bcPlayButtonSVG">
                      <path
                        d="M14.029 7.087l-14 7v-14z"
                        fill="#FFFFFF"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </a>
              </div>
            </div>
            {apiData.external_links && apiData.external_links.length > 0 && (
              <div className="mb__external__links__wrapper">
                {apiData.external_links.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className=""
                      style={{ padding: "3px 5px 3px 0" }}
                    >
                      <button type="button" title={item.name} className="">
                        <div width="28px" className="">
                          <a href={item.link_url} target="_blank">
                            <img
                              src={item.icon}
                              style={{
                                filter: "grayscale(1)",
                              }}
                            />
                          </a>
                        </div>
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
            {apiData.synopsis && (
              <div className="synopsisMobileWrapper">
                <div className="css-1qzk91j e1hh90gr1">
                  <div className="showingCategoryWrapper">
                    <section className="synopsisMobileSection">
                      <h2 className="synopsisMobileTitle">Synopsis</h2>
                      <div
                        itemProp="description"
                        className="synopsisMobileDesc"
                      >
                        <p>{apiData.synopsis}</p>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            )}
            {apiData.our_take && (
              <div className="ourtakeMobileWrapper">
                <div className="css-1qzk91j e1hh90gr1">
                  <div
                    className="showingCategoryWrapper"
                    style={{ backgroundColor: "white" }}
                  >
                    {apiData.our_take && (
                      <section className="ourtakeMobileSection">
                        <h2 className="ourtakeMobileTitle">
                          Programmer’s notes
                        </h2>
                        <div
                          itemProp="description"
                          className="ourtakeMobileDesc"
                        >
                          <p>{apiData.our_take}</p>
                        </div>
                      </section>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerHead;

{
  /* <div className="bsInfoBarGeneral">
Buy
</div> */
}
