import { service } from "../Network/service";
import moment from "moment";

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};
export const getBrowserType = () => {
  var isFirefox = typeof InstallTrigger !== "undefined";
  if (isFirefox === true) {
    return "Firefox";
  }
  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof window["safari"] !== "undefined" &&
          window["safari"].pushNotification)
    );
  if (isSafari === true) {
    return "Safari";
  }
  var isIE = false || !!document.documentMode;
  if (isIE === true) {
    return "IE";
  }
  var isEdge = !isIE && !!window.StyleMedia;
  if (isEdge === true) {
    return "Edge";
  }
  var isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  if (isChrome === true) {
    return "Chrome";
  }
  var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;
  if (isEdgeChromium === true) {
    return "EdgeChromium";
  }
};
export const getSessionId = () => {
  let date = new Date();
  let millis = date.getTime();
  let deviceId = localStorage.getItem("deviceId");
  let sessionId = millis + deviceId;
  localStorage.setItem("session_id", sessionId);
};
export const convertAdUrl = (videoDetails, showDetails) => {
  const adUrl = showDetails.ad_link;
  const currentLocation = JSON.parse(localStorage.getItem("currentLocation"));
  let uId = 280;
  let user_id = service.getCookie("userId");
  if (user_id) {
    uId = user_id;
  }
  const width = window.innerWidth;
  const height = window.innerHeight;
  const dnt = 0;
  const ipAddress = currentLocation.IPv4;
  const latitude = currentLocation.latitude;
  const longitude = currentLocation.longitude;
  const userAgent = navigator.userAgent;
  const deviceIfa = "";
  const uuid = "";
  const country = currentLocation.country_name;
  const deviceId = localStorage.getItem("deviceId");
  const keyword = showDetails.categories[0].category_name;
  const deviceModel = navigator.userAgent;
  const deviceMake = navigator.userAgent;
  const channelId = process.env.REACT_APP_CHANNELID;
  const userId = uId;
  const videoId = videoDetails.video_id;
  const bundleId = "";
  const appName = "justwatchme";
  const duration = showDetails.videos[0].video_duration
    ? showDetails.videos[0].video_duration * 60
    : "";
  const appstoreUrl = window.location.href;
  const city = currentLocation.city;
  const region = currentLocation.state;
  const showid = showDetails.show_id;
  const categories = showDetails.categories
    .map((item) => item.category_id)
    .join();

  const finalAdurl = adUrl
    .replace("[WIDTH]", width)
    .replace("[HEIGHT]", height)
    .replace("[DNT]", dnt)
    .replace("[IP_ADDRESS]", ipAddress)
    .replace("[LATITUDE]", latitude)
    .replace("[LONGITUDE]", longitude)
    .replace("[USER_AGENT]", userAgent)
    .replace("[DEVICE_IFA]", deviceIfa)
    .replace("[UUID]", uuid)
    .replace("[COUNTRY]", country)
    .replace("[DEVICE_ID]", deviceId)
    .replace("[KEYWORDS]", keyword)
    .replace("[DEVICE_MODEL]", deviceModel)
    .replace("[DEVICE_MAKE]", deviceMake)
    .replace("[CHANNEL_ID]", channelId)
    .replace("[USER_ID]", userId)
    .replace("[VIDEO_ID]", videoId)
    .replace("[BUNDLE]", bundleId)
    .replace("[APP_NAME]", appName)
    .replace("[DURATION]", duration)
    .replace("[APP_STORE_URL]", appstoreUrl)
    .replace("[CITY]", city)
    .replace("[REGION]", region)
    .replace("[SHOW_ID]", showid)
    .replace("[CATEGORIES]", categories);

  return finalAdurl;
};
export const deviceDetect = () => {
  let screenWidth =
    window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  return screenWidth > 959 ? true : false;
};
export const convertTimeToLocal = (date) => {
  let tempDate = moment.utc(date);
  let localDate = moment(tempDate).local();
  let timeData = localDate.format("hh:mm A");
  return timeData;
};
export const getDateStatus = (d) => {
  let newDate = new Date();
  var date = new Date(d);
  if (newDate < date) {
    if (
      date.getDate() == newDate.getDate() &&
      date.getMonth() == newDate.getMonth() &&
      date.getFullYear() == newDate.getFullYear()
    ) {
      return "";
    } else {
      let tempDate = moment.utc(d);
      let localDate = moment(tempDate).local();
      let timeData = localDate.format("DD-MM-YYYY");
      return timeData;
    }
  } else if (newDate > date) {
    return false;
  } else {
    return false;
  }
};
export const playerController = (position, playerId) => {
  let screenPosition = document.documentElement.scrollTop;
  let playerStream = document.getElementById(playerId);
  if (playerStream !== null && playerStream !== "undefined") {
    if (screenPosition > position) {
      playerStream.pause();
    } else if (screenPosition < position) {
      if (playerStream != null) {
        if (playerStream.paused) {
          playerStream.play();
        }
      }
    }
  }
};
export const validateName = (stringValue) => {
  if (/^[A-Za-z ]+$/.test(stringValue.trim())) {
    return true;
  }
  return false;
};
export const validateEmail = (email) => {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
  ) {
    return true;
  }
  return false;
};
export const swipedetect = (el, callback) => {
  var touchsurface = el,
    swipedir,
    startX,
    startY,
    distX,
    distY,
    threshold = 100, //required min distance traveled to be considered swipe
    restraint = 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime = 300, // maximum time allowed to travel that distance
    elapsedTime,
    startTime,
    handleswipe = callback || function (swipedir) {};

  touchsurface.addEventListener(
    "touchstart",
    function (e) {
      var touchobj = e.changedTouches[0];
      swipedir = "none";
      var dist = 0;
      startX = touchobj.pageX;
      startY = touchobj.pageY;
      startTime = new Date().getTime(); // record time when finger first makes contact with surface
    },
    false
  );

  touchsurface.addEventListener("touchmove", function (e) {}, false);

  touchsurface.addEventListener(
    "touchend",
    function (e) {
      var touchobj = e.changedTouches[0];
      distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
      distY = touchobj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
      elapsedTime = new Date().getTime() - startTime; // get time elapsed
      if (elapsedTime <= allowedTime) {
        // first condition for awipe met
        if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
          // 2nd condition for horizontal swipe met
          swipedir = distX < 0 ? "left" : "right"; // if dist traveled is negative, it indicates left swipe
        } else if (
          Math.abs(distY) >= threshold &&
          Math.abs(distX) <= restraint
        ) {
          // 2nd condition for vertical swipe met
          swipedir = distY < 0 ? "up" : "down"; // if dist traveled is negative, it indicates up swipe
        }
      }
      handleswipe(swipedir);
    },
    false
  );
};
const stringify = (str) => {
  if (str != undefined && str != null) {
    return String(str);
  } else {
    return "";
  }
};
export const ssaiAdParam = async (videoDetails) => {
  const currentLocation = JSON.parse(localStorage.getItem("currentLocation"));
  // let uId = 74961;
  // let user_id = service.getCookie('userId');
  // if (user_id) {
  //     uId = user_id;
  // }

  var adsParams = {
    adsParams: {
      advid: "",
      appname: stringify(videoDetails.channel_name),
      bundleid: "https://staging.justwatchme.tv/",
      channelid: stringify(videoDetails.channel_id),
      country: stringify(currentLocation.country_name),
      description_url: "JustWatchMe",
      device_make: stringify(navigator.userAgent),
      device_model: stringify(navigator.userAgent),
      device_type: stringify(navigator.userAgent),
      deviceid: stringify(localStorage.getItem("deviceId")),
      dnt: "true",
      height: stringify(window.innerHeight),
      ip: stringify(currentLocation.IPv4),
      kwds: "JustWatchMe",
      lat: stringify(currentLocation.latitude),
      lon: stringify(currentLocation.longitude),
      totalduration: "",
      ua: stringify(navigator.userAgent),
      userid: stringify(service.getCookie("userId")),
      uuid: "",
      videoid: stringify(videoDetails.video_id),
      width: stringify(window.innerWidth),
    },
  };

  // var adsParams = {
  //   adsParams: {
  //     advid: "",
  //     appname: stringify(videoDetails.channel_name),
  //     bundleid: "https://fantastic-entertainment.com/",
  //     channelid: stringify(videoDetails.channel_id),
  //     country: stringify(currentLocation.country_name),
  //     description_url: "Fantastic-Entertainment",
  //     device_make: stringify(navigator.userAgent),
  //     device_model: stringify(navigator.userAgent),
  //     device_type: stringify(navigator.userAgent),
  //     deviceid: stringify(localStorage.getItem("deviceId")),
  //     dnt: "true",
  //     height: stringify(window.innerHeight),
  //     ip: stringify(currentLocation.IPv4),
  //     kwds: "Fantastic-Entertainment",
  //     lat: stringify(currentLocation.latitude),
  //     lon: stringify(currentLocation.longitude),
  //     totalduration: "",
  //     ua: stringify(navigator.userAgent),
  //     userid: stringify(service.getCookie("userId")),
  //     uuid: "",
  //     videoid: stringify(videoDetails.video_id),
  //     width: stringify(window.innerWidth),
  //   },
  // };

  return adsParams;
};
export const checkOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  //Check mobile device is Android
  if (/android/i.test(userAgent)) {
    //Add your Code here
    return "android";
  }

  //Check mobile device is IOS
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    //Add your Code here
    return "iPhone";
  }

  //Check device os is Windows (For Laptop and PC)
  if (navigator.appVersion.indexOf("Win") != -1) {
    //Add your Code here
    return "window";
  }

  //Check device os is MAC (For Laptop and PC)
  if (navigator.appVersion.indexOf("Mac") != -1) {
    //Add your Code here
    return "mac";
  }
  return "none";
};
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
export function restrictAlphabets(e) {
  if (
    (e.keyCode >= 65 && e.keyCode <= 90) ||
    (e.keyCode >= 106 && e.keyCode <= 111) ||
    e.keyCode === 222 ||
    e.keyCode === 189 ||
    e.keyCode === 188 ||
    e.keyCode === 190 ||
    e.keyCode === 191 ||
    e.keyCode === 186 ||
    e.keyCode === 219 ||
    e.keyCode === 220 ||
    e.keyCode === 221 ||
    e.keyCode === 192 ||
    e.keyCode === 187
  ) {
    return e.preventDefault();
  }
}
