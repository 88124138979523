import React, { useState, useEffect } from "react";
import "./Rating.css";
import CloseIcon from "@material-ui/icons/Close";
import RatingStar from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import { service } from "./service";

const RatingPopUp = (props) => {
  const [value, setValue] = useState(0);
  const [alertmsg, setAlertmsg] = useState(false);
  const [reviewlimit, setReviewlimit] = useState(420);
  const [review, setReview] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);

  let limit = 420;

  useEffect(() => {
    if (props.apiData.user_rating) {
      setValue(props.apiData.user_rating);
    }
  }, []);

  const resetValues = () => {
    setValue(0);
    setAlertmsg(false);
    setReviewlimit(420);
    setReview("");
  };

  const onChangeReview = (e) => {
    e.preventDefault();
    setReview(e.target.value);
    setReviewlimit(limit - e.target.value.length);
    if (value == 0 && e.target.value) {
      setAlertmsg(true);
    } else {
      setAlertmsg(false);
    }
  };

  const onSubmithandler = (e) => {
    e.preventDefault();
    service
      .RateandReview(props.apiData.show_id, value, review)
      .then((response) => {
        if (response.success == true) {
          props.onClose();
          props.userRatedSuccess(value);
          resetValues();
        }
      });
  };

  if (!props.show) {
    return null;
  } else {
    return (
      <div
        className="modal-rating-popup"
        onClick={(e) => {
          props.onClose();
          resetValues();
        }}
      >
        <div
          className="modal-rating-content"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <CloseIcon
            className="modal-rating-close-btn"
            onClick={(e) => {
              props.onClose();
              resetValues();
            }}
          />
          <div className="modal-rating-header">
            <div className="modal-rating-title">
              <h1>{props.apiData.show_name}</h1>
              <div>
                <span className="modal-rating-director">
                  {props.apiData.director}
                </span>{" "}
                <span>{props.apiData.year}</span>
              </div>
            </div>
            <div className="rating-star">
              <Box
                align="left"
                component="fieldset"
                mb={0}
                borderColor="transparent"
              >
                <RatingStar
                  value={value}
                  name="rating"
                  max={5}
                  style={{ fontSize: "3.2rem", color: "rgb(251, 231, 37)" }}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                    if (newValue == 0) {
                      setAlertmsg(true);
                    } else {
                      setAlertmsg(false);
                    }
                  }}
                />
              </Box>
            </div>
            {alertmsg && (
              <div className="modal-rating-alert-container">
                <div className="modal-rating-alert-text">
                  Please{" "}
                  <strong>
                    rate<strong> the film to save your review</strong>
                  </strong>
                </div>
              </div>
            )}
            <div className=""></div>
          </div>
          <div className="modal-rating-body">
            <form onSubmit={onSubmithandler}>
              <textarea
                name="body"
                placeholder="Add a review?"
                className="modal-rating-textArea"
                value={review}
                onChange={onChangeReview}
              ></textarea>
              <div className="modal-rating-footer">
                <div
                  className=""
                  style={
                    reviewlimit >= 0
                      ? { color: "rgb(200, 200, 200)" }
                      : { color: "red" }
                  }
                >
                  {reviewlimit} characters remaining
                </div>
                <div className="">
                  <button
                    disabled={value == 0 || reviewlimit < 0 ? true : false}
                    type="submit"
                    className="rating-save-btn"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default RatingPopUp;
