import * as actionTypes from '../action/actions';

const initial_state = {
    login: false,
    showId: '',
    addToMyList: false,
    signInBlock: false,
    currentExpand: null,
    prvExpand: null,
    updateHeader: false,
    isAndroid: false,
    username:""

};

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case actionTypes.LOGIN:
            return {
                ...state,
                login: true
            };
        case actionTypes.LOGOUT:
            return {
                ...state,
                login: false
            };
        case actionTypes.SHOW_ID:
            return {
                ...state,
                showId: action.payload
            }
        case actionTypes.ADD_TO_MY_LIST:
            return {
                ...state,
                addToMyList: !state.addToMyList
            }
        case actionTypes.SIGN_IN_BLOCK:
            return {
                ...state,
                signInBlock: !state.signInBlock
            }
        case actionTypes.SET_CURRENT_EXPAND:
            return {
                ...state,
                currentExpand: action.payload
            }
        case actionTypes.SET_PRV_EXPAND:
            return {
                ...state,
                prvExpand: action.payload
            }
        case actionTypes.UPDATE_MENU:
            return {
                ...state,
                updateHeader: !state.updateHeader
            }
        case actionTypes.SET_ANDROID:
            return {
                ...state,
                isAndroid: true
            };
            case actionTypes.USERNAME:
                return {
                    ...state,
                    username: action.payload
                };
        default:
            return state;
    }
}

export default reducer;
