import React, { useState, useEffect } from "react";
import { service } from "../../Network/service";
import Slider from "../Slider/Slider";
import Banner from "../Banner/Banner";
import FBanner from "../NewArrivals/FBanner";
import CategoryContainer from "./CategoryContainer";
import Notification from "../../common/Notification";
import { useSelector, useDispatch } from "react-redux";
import TrailerPopUp from "../TrailerPopUp/TrailerPopUp";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { useHistory } from "react-router-dom";

const Home = () => {
  let path = window.location.pathname;
  const history = useHistory();
  path = path.length == 1 ? "/home" : path;
  const [filmoftheDay, setFilmoftheDay] = useState([]);
  const [categories, setCategories] = useState([]);
  const [FODBanner, setFODBanner] = useState([]);
  const [freeCategory, setFreeCategory] = useState([]);
  const [watchList, setWatchList] = useState([]);
  const [bannerDetails, setBannerDetails] = useState([]);
  const signInBlock = useSelector((state) => state.signInBlock);
  const [showTrailer, setShowTrailer] = useState(false);
  const [apiRes, setApiRes] = useState("");
  const [continueWatching, setContinueWatching] = useState([]);
  const [SubscribeFlag, setSubscribeFlag] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("scroll-top").scroll(0, 0);
    updateFunction();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("isLoggedIn") == "true") {
      checkSubscription(localStorage.getItem("userId"));
    }
  }, []);

  const updateFunction = () => {
    var singleObj = [];

    var freeArray = [];
    var myList = [];

    var ContinueList = [];

    service.homeBannerDetails().then((response) => {
      if (response.success === true) {
        console.log(response.data,"response.data");
        
        setBannerDetails(response.data);
      } else {
        setBannerDetails([]);
      }
    });

    service.freeVideos().then((response) => {
      if (response.data && response.data.length > 0) {
        let freeObj = {};
        freeObj.category_id = 140;
        freeObj.category_name = "Watch for free";
        freeObj.shows = response.data;
        freeArray.push(freeObj);
        setFreeCategory(freeArray);
      } else {
        setFreeCategory([]);
      }
    });

    service.getFileOfTheWeek().then((response) => {
      if (
        response.success == true &&
        response.data &&
        response.data.length > 0
      ) {
        let newObj = {};
        let shows;
        newObj.category_id = "Film of the week"; //need to change id
        newObj.category_name = "Film of the week";
        newObj.shows = response.data.filter((item) => item.week != "THIS WEEK");
        shows = response.data.filter((item) => item.week == "THIS WEEK");
      
        singleObj.push(newObj);
        setFODBanner(shows);
        if (newObj.shows.length > 0) {
          setFilmoftheDay(singleObj);
        } else {
          setFilmoftheDay([]);
        }
      }
    });

    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      service.playList().then((response) => {
        if (response.data && response.data.length > 0) {
          let mylistObj = {};
          mylistObj.category_id = "playlist";
          mylistObj.category_name = "Your Watchlist";
          mylistObj.shows = response.data;
          myList.push(mylistObj);
          setWatchList(myList);
        } else if (response.data && response.data.length == 0) {
          setWatchList([]);
        }
      });
      service.getContinueWatchingVideos().then((response) => {
        if (response.success === true && response.data.length > 0) {
          let videoObj = {};
          videoObj.category_id = "Continue-watching";
          videoObj.category_name = "Continue watching";
          videoObj.shows = response.data;
          ContinueList.push(videoObj);
          setContinueWatching(ContinueList);
        } else {
          setContinueWatching([]);
        }
      });
    }

    service.getshowsbyCategory().then((response) => {
      if (response.success === true && response.data.length > 0) {
        var data = response.data;
        setCategories(data);
      }
    });
  };

  const onClickTralier = (showId) => {
    setShowTrailer(true);
    service.getShowDetails(showId).then((response) => {
      if (response.success == true && response.data) {
        setApiRes(response.data);
      }
    });
  };

  //

  const checkSubscription = (uId) => {
    service.userSubscription(uId).then((response) => {
      if(response.data.length>0){
        setSubscribeFlag(true) //flag becomes true if usr subscribed
      }
      else{
        setSubscribeFlag(false) //flag becomes false if usr hasn't subscribed
      }
      if (response.success == true) {
        if (response.forcibleLogout === true) {
          alert(
            "Sorry, You’ve reached the maximum Device limit. Please log in again!"
          );
          service.logoutAll(uId).then((res) => {
            setTimeout(() => {
              redirectToLogin();
            }, 1000);
          });
        } else {
          if (response.session_expired === true) {
            ToastsStore.warning("Sorry! Session Has Expired");
            setTimeout(() => {
              redirectToLogin();
            }, 1000);
          }
        }
      }
    });
  };

  const redirectToLogin = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    localStorage.removeItem("deviceAnalytics");
    service.setCookie("isLoggedIn", false, 30);
    localStorage.removeItem("isLoggedIn");
    service.eraseCookie("showId");
    service.eraseCookie("isScreeningUser");
    service.eraseCookie("userName");
    service.eraseCookie("userId");
    service.eraseCookie("userEmail");
    service.eraseCookie("subscriptionId");
    setTimeout(() => {
      window.location.href = "/signin";
    }, 1500);
  };

  //

  return (
    <div className="home__wrapper">
      {signInBlock === true ? <Notification /> : null}
      {showTrailer && apiRes && (
        <TrailerPopUp
          show={showTrailer}
          apiData={apiRes}
          onClose={() => {
            setShowTrailer(false);
            setApiRes("");
          }}
        />
      )}
      {path.startsWith("/home") && bannerDetails.length > 0 && (
        <Banner bannerDetails={bannerDetails} clickHandler={updateFunction} />
      )}
      {(!SubscribeFlag) && (path.startsWith("/home") && (
        <div className="free__videos">
          {freeCategory.length > 0 ? (
            <Slider
              category={freeCategory[0]}
              clickHandler={updateFunction}
              onClickTralier={onClickTralier}
            />
          ) : null}
        </div>
      ))}
      {path.startsWith("/home") && (
        <div className="your__watchlist">
          {watchList.length > 0 ? (
            <Slider
              category={watchList[0]}
              clickHandler={updateFunction}
              onClickTralier={onClickTralier}
            />
          ) : null}
        </div>
      )}

      {path.startsWith("/home") && (
        <div className="continue__watching">
          {continueWatching.length > 0 ? (
            <Slider
              category={continueWatching[0]}
              clickHandler={updateFunction}
              onClickTralier={onClickTralier}
            />
          ) : null}
        </div>
      )}
      {path.startsWith("/home") && FODBanner.length > 0 && (
        <FBanner show={FODBanner[0]} clickHandler={updateFunction} />
      )}
      {path.startsWith("/home") && (
        <div className="Film__of__the__week">
          {filmoftheDay.length > 0 ? (
            <Slider
              category={filmoftheDay[0]}
              clickHandler={updateFunction}
              onClickTralier={onClickTralier}
            />
          ) : null}
        </div>
      )}

      {categories.map((category, index) => {
        let count = 0;
        if (path.startsWith("/movies")) {
          count = category.shows.filter((item) => item.single_video == 1);
        } else if (path.startsWith("/tvshows")) {
          count = category.shows.filter((item) => item.single_video == 0);
        } else if (path.startsWith("/home")) {
          count = category.shows.length;
        } else {
          count = -1;
        }
        if (count != 0) {
          return (
            <div key={index}>
              <CategoryContainer
                categoryDetails={category}
                clickHandler={updateFunction}
                onClickTralier={onClickTralier}
              />
            </div>
          );
        } else {
          return null;
        }
      })}
      <ToastsContainer store={ToastsStore} />
    </div>
  );
};
export default Home;
