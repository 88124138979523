//sign out of all device

import React, { useState } from "react";
import { service } from "./service";
import "./signout.css";

const Signout = () => {
  const [signOutStatus, setSignOutStatus] = useState(false);
  const handleSignOutClick = () => {
    let user_id = service.getCookie("userId");
    service.logoutAll(user_id).then((response) => {
      if (response.success === true) {
        setSignOutStatus(true);
        localStorage.removeItem("userName");
        localStorage.removeItem("userId");
        service.setCookie("isLoggedIn", false, 30);
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("deviceAnalytics");
        service.eraseCookie("userName");
        service.eraseCookie("userId");
        service.eraseCookie("isScreeningUser");
        service.eraseCookie("userEmail");
        service.eraseCookie("subscriptionId");
        service.eraseCookie("showId");
        sessionStorage.removeItem("applaunch");
        window.location.href = "/signIn";

        // window.location.href = process.env.REACT_APP_PAYMENT_URL + 'login'
      }
    });
  };

  return (
    <div className="mainClass">
      <div style={{ padding: "50px" }}>
        <div className="responsive-account-container">
          <form method="POST" action="#">
            <h1 className="signOutHeading">Sign Out of All Devices</h1>
            <p className="subText">
              Are you sure you want to sign out of this JustWatchMe.tv account
              on all devices?
            </p>

            <div className="btn-bar btn-bar-left" data-uia="btn-submit">
              <button
                // className="btn btn-blue btn-small"
                className="signOutButton"
                type="button"
                onClick={handleSignOutClick}
                style={{ width: "95px" }}
                // autoComplete="off"
                // tabindex="0"
                // data-uia="btn-sign-out"
                // onclick="Logoutall()"
              >
                Sign Out
              </button>
              <a href="/account">
                <button
                  className="cancelButton"
                  style={{ width: "90px" }}
                  // className="btn btn-plain btn-small"
                  // onclick="cancel()"
                  type="button"
                  // autoComplete="off"
                  // tabindex="0"
                  // data-uia="btn-cancel"
                >
                  Cancel
                </button>
              </a>
            </div>
          </form>
          {signOutStatus ? (
            <div
              className="row d-flex justify-content-center"
              style={{ margin: "30px" }}
            >
              <div className="alert alert-warning col-md-8" role="alert">
                <h4 className="alert-heading"></h4>
                <p>Logged out successfully</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Signout;
